/* eslint no-lone-blocks: 0 */
/* eslint eqeqeq: 0 */
/* eslint no-unused-vars: 0 */
/* eslint array-callback-return: 0 */
/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, {Component} from 'react';
import Tab from 'semantic-ui-react/dist/es/modules/Tab';
import Label from 'semantic-ui-react/dist/es/elements/Label';
import Input from 'semantic-ui-react/dist/es/elements/Input';
import Comment from 'semantic-ui-react/dist/es/views/Comment';
import Segment from 'semantic-ui-react/dist/es/elements/Segment';
import Header from 'semantic-ui-react/dist/es/elements/Header';
import {Form, Dropdown, Checkbox} from 'formsy-semantic-ui-react';
import Menu from 'semantic-ui-react/dist/es/collections/Menu';
import Grid from 'semantic-ui-react/dist/es/collections/Grid';
import Button from 'semantic-ui-react/dist/es/elements/Button';
import Icon from 'semantic-ui-react/dist/es/elements/Icon';
import Sticky from 'semantic-ui-react/dist/es/modules/Sticky';
import global from "./global"
import {toast} from 'react-toastify';
import MaskedInput from 'react-maskedinput'
import NumericInput from 'react-numeric-input';
import Sortable from 'react-sortablejs';
import Modal from 'semantic-ui-react/dist/es/modules/Modal';
import DropZone from "react-dropzone"
import Moment from "react-moment"
import CKEditor from 'react-ckeditor-wrapper'
import ReactTable from "react-table";
import {confirmAlert} from 'react-confirm-alert'; // Import
import NumberFormat from 'react-number-format';
import Loader from 'semantic-ui-react/dist/es/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/es/modules/Dimmer';
import Accordion from 'semantic-ui-react/dist/es/modules/Accordion'

export class ContractEditRecordOswego extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access_token: this.props.access_token,
            recid: this.props.recid,
            mandate_classification: this.props.data.rec.mandate_classification,
            data: this.props.data,
            cdata: [],
            users: this.props.users,
            dirtyFlag: this.props.dirtyFlag,
            confirmOpen: false,
            addl_data: {},
            randomkey: Math.random(),
            showVendorContactModal: false,
            current_user: JSON.parse(sessionStorage.getItem("current_user")),
            user_fields: this.props.user_fields,
            showcontactmodal: false,
            liaisons: this.props.data.liaisons,
            finaldata: {},
            orgoptions: this.props.data.rec.organization_name !== " " ? this.props.data.rec.organization_name : [],
            orgaddress: this.props.data.rec.orgaddress,
            orgcontacts: this.props.data.rec.orgcontacts,
            randomkey2: Math.random(),
            randomkey3: Math.random(),
            accessusers: [],
            saving: 0,
            docactiveIndex: 0,
            activeindex: 0,
            showroutingmodal: false,
            route: [{id: null, desc: "", signatory: "approval"}],
            objective: [{id: null, frequency: "M", description: " "}],
            showinsuranceimport: false,
            showobjectivemodal: false,
            showdocsimport: false,
            randomkey4: Math.random(),
            randomkeye: Math.random(),
            randomkeypo: Math.random(),
            randomkeyom: Math.random(),
            randomkeycd: Math.random(),
            randomkey3x: Math.random(),
            randomkeyccr: Math.random(),
            objective_measurements: [],
            selected: null,
            selectedName: "",
            columns: this.props.columns,
            itypes: this.props.itypes,
            showapproval: false,
            current_step: {},
            showdimmer: false,
            saveinprocess: 0,
            showcopymodal: false,
            cpages: 0,
            cloading: false,
            route_steps: {},
            showamendmodal: false,
            showrenewmodal: false,
            showmessageform: false,
            randomkeyform: Math.random()


        };
        this.template_data = this.props.data.rec.template


    }

    componentWillUpdate = () => {
        this.fixBody();
    }

    componentDidUpdate = () => {
        this.fixBody();
    }

    fixBody = () => {
        const anotherModal = document.getElementsByClassName('ui page modals').length;
        if (anotherModal > 0) document.body.classList.add('scrolling', 'dimmable', 'dimmed');
    }

    showDocument = (rowInfo) => {
        var parms = {
            docid: rowInfo.row.id
        }

        global.getFetch("documents/download", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })


    }
    showContractDocument = (event, data) => {
        var parms = {
            docid: this.state.data.rec.contract_document_id
        }

        global.getFetch("documents/download", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })

    }
    handleFocus = (event) => event.target.select();
    copyroutes = (rowInfo) => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to copy the routing to this contract? This will replace any current routing',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,
                    rtid: rowInfo.row.id
                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/copy_route", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.notifySuccess("Routes have been copied")
                        this.loadRecord()
                        this.setState({showdimmer: false, showcopymodal: false})
                    } else {

                    }


                })
            }
        })
    }
    generateContractDocument = () => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to generate the contract? This will become the current working version and replace all prior versions',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,
                    template: this.template_data,
                    doc_name: this.refs.editForm.getModel().contract_doc_name
                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/generate_contract", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.notifySuccess("Contract Document Generated")
                        this.loadRecord()
                        this.setState({showdimmer: false})


                    } else {
                        this.setState({showdimmer: false})
                        this.notifyError(resp.error)
                        this.loadRecord()


                    }


                })
            }
        })
    }


    showInsuranceDocument = (rowInfo) => {
        var parms = {
            docid: rowInfo.row.id
        }

        global.getFetch("documents/insurancedownload", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })


    }
    checkDirty = (currentValues, isChanged) => {

        var savedData = this.state.data.rec
        Object.keys(currentValues).map(x => {
            if (currentValues[x] !== savedData[x]) {

            }
        })
    }
    showContractInsuranceDocument = (rowInfo) => {
        var parms = {
            docid: rowInfo.row.id
        }

        global.getFetch("documents/contractinsurancedownload", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.file)

        })


    }
    DeleteDocument = (recid) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this document?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {
                var parms = {
                    id: recid,
                    field: "contract_id",
                    fieldvalue: this.state.recid
                }
                global.getFetch("documents/delete", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.notifySuccess(resp.message)
                        this.loadRecord()


                    } else {
                        this.notifyError(resp.message)

                    }

                })
            }
        })

    }
    notifyError = (errors) => {
        toast.error(errors + "\n(click anywhere to close)", {autoClose: 10000})
    }

    updateRoutingTree = (tree) => {
        var parms = {tree: tree, contract_id: this.state.recid}
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        global.postFetch("contracts/updaterouteorder", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {

            }
        })
    }
    onAttachmentDrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('id', this.state.recid)
            formData.append('type', "contract")
            formData.append('subtype', "A")
            var comp = this
            global.postFetchx("documents/upload", formData, this.state.access_token).then(function (resp) {
                var tdata = comp.state.data
                tdata.rec.attachments = resp.attachments
                comp.setState({
                    data: tdata
                })


            });
        })
    }
    onAttachmentADrop = (acceptedFiles) => {
        acceptedFiles.forEach(file => {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('id', this.state.recid)
            formData.append('type', "contract")
            formData.append('subtype', "1")
            var comp = this
            global.postFetchx("documents/upload", formData, this.state.access_token).then(function (resp) {
                var tdata = comp.state.data
                tdata.rec.schedule_a_docs = resp.schedule_a_docs
                comp.setState({
                    data: tdata
                })


            });
        })
    }
    notifySuccess = (msg) => {
        toast.success(msg, {autoClose: 3000})
    }
    ChangeCanEdit = (ci, et) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {id: et.id, canedit: et.checked}
        global.postFetch("contracts/updatecanedit", parms, this.state.access_token).then(resp => {
            this.notifySuccess("Permissions have been updated")


        })
    }
    ChangeAttachment = (ci, et) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {id: et.id, include: et.checked}
        global.getFetch("contracts/update_attachment", parms, this.state.access_token).then(resp => {
            this.notifySuccess("Attachment Updated")


        })
    }
    mapOrder = (array, order, key) => {
        var newarray = []
        order.map(function (d) {
                array.map(function (x) {
                    if (parseInt(x["data"][key]) === parseInt(d)) {
                        newarray.push(x)
                    }
                })
            }
        )
        return newarray;
    };
    deleteApprovalStep = (a, b) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {
            id: b.id
        }
        confirmAlert({
                title: 'Confirm Delete',                        // Title dialog
                message: 'Are you sure you want to delete this Approval Step?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {

                    global.getFetch("contracts/deleteapprovalstep", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess(resp.message)
                            var data = this.state.data
                            data.rec.routing_users = resp.routing_users
                            this.setState({
                                data: data,


                            })
                        } else {
                            this.notifyError(resp.message)
                        }
                    })
                }
            }
        )
    }
    DeleteObjective = (b) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {
            id: b
        }
        confirmAlert({
                title: 'Confirm Delete',                        // Title dialog
                message: 'Are you sure you want to delete this Objective?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {

                    global.getFetch("contracts/deleteobjective", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess(resp.message)
                            this.loadRecord()
                        } else {
                            this.notifyError(resp.message)
                        }
                    })
                }
            }
        )
    }
    editApprovalStep = (a, b) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {
            id: b.id
        }

        global.getFetch("contracts/editapprovalstep", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {

                this.setState({
                    route: resp.route,
                    showroutingmodal: true


                })
            } else {
                this.notifyError(resp.message)
            }
        })
    }
    EditObjective = (row) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {
            id: row
        }

        global.getFetch("contracts/editobjective", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                var objective = this.state.objective
                objective.id = resp.data.id
                objective.frequency = resp.data.frequency
                objective.description = resp.data.description
                this.setState({objective: objective, showobjectivemodal: true})
            } else {
                this.notifyError(resp.message)
            }
        })
    }

    handleAccordionClick = (e, titleProps) => {
        const {index} = titleProps
        const {activeIndex} = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({activeIndex: newIndex})
    }

    renderEditCheck = (cellInfo) => {
        var is_checked = false
        if(cellInfo.index >= this.state.data.contract_users.length) {
            is_checked = false
        } else {
            is_checked = this.state.data.contract_users[cellInfo.index][cellInfo.column.id]
        }
        return (
            <Form.Checkbox
                name="can_edit"
                id={cellInfo.row.id}
                onChange={this.ChangeCanEdit}
                checked={is_checked}

            />


        );
    }
    renderInclude = (cellInfo) => {
        var is_checked = false
        if(cellInfo.index >= this.state.data.rec.attachments.length) {
            is_checked = false
        } else {
            is_checked = this.state.data.rec.attachments[cellInfo.index][cellInfo.column.id]
        }
        return (
            <Form.Checkbox
                name="nosave_include"
                id={cellInfo.row.id}
                onChange={this.ChangeAttachment}
                defaultChecked={is_checked}

            />


        );
    }
    loadRecord = () => {
        var parms = {
            id: this.state.data.rec.id
        }
        var component = this
        global.getFetch("contracts/read", parms, this.state.access_token).then(function (resp) {
            component.setState({
                data: resp,
                showmessageform: false,
                showdimmer: false

            });
        })
    }
    handleSave = () => {
        this.setState({saving: 1}, function () {
            this.refs.editForm.submit()
        })

    }
    loadOrganization = (event, {value}) => {
        var component = this
        var parms = {id: value}
        global.postFetch("common/loadorganization", parms, component.state.access_token).then(function (resp) {
            if (resp.success === "Y") {
                component.setState({
                    orgaddress: resp.orgaddress,
                    orgcontacts: resp.orgcontacts
                })
            }
        })
    }
    newApproval = () => {
        var route = this.state.route
        route.signatory = "approval"
        route.id = null
        route.desc = null
        route.user_id = null
        route.locked = false
        route.phase_end = false
        this.setState({route: route, showroutingmodal: true})
    }
    loadUser = (event, {value}) => {
        var component = this
        var parms = {id: value, contract_id: this.state.data.rec.id}
        global.postFetch("contracts/adduser", parms, component.state.access_token).then(resp => {
            var data = component.state.data
            data.contract_users = resp.users
            this.notifySuccess("User has been added to Contract")
            component.setState({
                accessusers: []
            })
            this.loadRecord()

        })
    }
    getliaisons = (event, {value}) => {
        var component = this
        var parms = {ids: value}
        global.postFetch("common/getliaisons", parms, component.state.access_token).then(function (resp) {
            if (resp.success === "Y") {
                component.setState({
                    liaisons: resp.liaisons
                })
            }
        })
    }
    captureChange = (currentValues, isChanged) => {

    }
    fee_desc_change = (c, i) => {
        var split = i.name.split(".");
        var data = this.state.data
        data.rec.fee_for_service[split[1]][split[2]] = i.value
        this.setState({data: data})


    }
    onRoutingValidSubmit = (formData, resetForm, invalidateForm) => {
        var comp = this
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var parms = {...formData, signatory: this.state.route.signatory, contract_id: this.state.recid}
        if (!formData.id) {
            global.postFetch("contracts/addroute", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    var data = comp.state.data
                    data.rec.routing_users = resp.routing_users
                    comp.setState({
                        data: data,
                        showroutingmodal: false

                    })
                } else {
                    comp.notifyError(resp.message)
                }
            })
        } else {
            global.postFetch("contracts/updateroute", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    this.notifySuccess(resp.message)
                    var data = this.state.data
                    data.rec.routing_users = resp.routing_users
                    this.setState({
                        data: data,
                        showroutingmodal: false
                    })
                } else {
                    this.notifyError(resp.message)
                }
            })
        }
    }
    AddMessage = () => {
        this.setState({showmessageform: true})
    }
    onSignatureValidSubmit = (formData, resetForm, invalidateForm) => {
        var parms = {
            ...formData,
            route: this.state.current_step,
            contract_id: this.state.recid,
            routing: this.state.routing,
            contract_approval: this.state.contract_approval
        }
        this.setState({showdimmer: true})

        global.postFetch("contracts/approval", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.setState({showapproval: false})
                this.notifySuccess(resp.message)
                this.loadRecord()
                this.setState({showdimmer: false})

            } else {
                this.setState({showdimmer: false})
                this.notifyError(resp.message)
            }
        })

    }
    onObjectiveValidSubmit = (formData, resetForm, invalidateForm) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }
        var parms = {
            ...formData,
            contract_id: this.state.recid,
            frequency: this.state.objective.frequency,
            id: this.state.objective.id
        }
        if (!this.state.objective.id) {
            global.postFetch("contracts/addobjective", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    this.notifySuccess(resp.message)
                    this.setState({
                        showobjectivemodal: false
                    })
                    this.loadRecord()
                } else {
                    this.notifyError(resp.message)
                }
            })
        } else {
            global.postFetch("contracts/updateobjective", parms, this.state.access_token).then(resp => {
                if (resp.success === "Y") {
                    this.notifySuccess(resp.message)
                    this.setState({
                        showobjectivemodal: false
                    })
                    this.loadRecord()
                } else {
                    this.notifyError(resp.message)
                }
            })
        }
    }
    getMeasures = (row) => {
        var parms = {id: row}
        global.getFetch("contracts/get_measures", parms, this.state.access_token).then(resp => {
            this.setState({
                objective_measures: resp.data,

            });
        })
    }
    numberFormat = (num) => {

    }
    onValidMessageSubmit = (formData, resetForm, invalidForm) => {
        var data = formData
        var parms = {...data}
        parms.id = this.state.recid
        this.setState({showdimmer: true})
        var comp = this

        global.postFetch("contracts/create_message", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                comp.notifySuccess(resp.message)
                var tdata = comp.state.data
                tdata.rec.messages = resp.rec.messages
                this.loadRecord()
            } else {
                comp.notifyError(resp.message)
            }
        })


    }
    onValidSubmit = (formData, resetForm, invalidateForm) => {
        if (this.state.saving === 0) {
            return;
        }
        if (this.state.data.rec.locked && formData.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }
        this.setState({saveinprocess: 1, showdimmer: true})
        var data = formData
        data.template = this.template_data
        data.fee_for_service = this.state.data.rec.fee_for_service
        data.schedule_a_text = this.state.data.rec.schedule_a_text
        data.schedule_b_text = this.state.data.rec.schedule_b_text
        var comp = this
        var addl_data = this.state.addl_data
        var parms = {...data, ...addl_data}
        parms.id = this.state.recid
        if (this.state.recid === null) {
            global.postFetch("contracts/create", parms, this.state.access_token).then(function (resp) {
                comp.setState({showdimmer: false})
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields,
                        saving: 0,
                        saveinprocess: 0
                    })
                    this.loadRecord()
                } else {
                    comp.notifyError(resp.message)
                }
            })

        } else {
            global.postFetch("contracts/update", parms, this.state.access_token).then(function (resp) {
                comp.setState({showdimmer: false})
                if (resp.success === "Y") {
                    comp.notifySuccess(resp.message)
                    comp.setState({
                        recid: resp.rec.id,
                        data: resp,
                        user_fields: resp.user_fields,
                        saving: 0,
                        saveinprocess: 0
                    })
                    this.loadRecord()
                } else {
                    comp.notifyError(resp.message)
                }
            })
        }

    }
    onValidCSubmit = (formData) => {
        this.setState({searchkey: formData.search, loading: true, randomkeyccr: Math.random()})
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        var component = this;
        var parms = {
            pageSize: this.state.pageSize,
            page: 1,
            sorted: this.state.sorted,
            search: formData.search,
            uid: obj.id

        }

        global.getFetch(this.props.path, parms, this.state.access_token).then(function (resp) {
            component.setState({
                cdata: resp.rows,
                cpages: resp.pages,
                cloading: false,
                parms: parms,

            });
        })
    }
    importMatchingInsurance = () => {
        var parms = {contract_id: this.state.recid}
        global.getFetch("contracts/import_matching_insurance", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.notifySuccess("Insurance Documents Imported")
                var data = this.state.data
                data.rec.ins_problem=resp.ins_problem
                data.rec.ins_problem_message=resp.ins_problem_message
                data.rec.contract_insurances = resp.contract_insurances
                data.rec.vendor_insurances = resp.vendor_insurances
                this.setState({
                    data: data
                })
            }
        })
    }
    DeleteContractUser = (id) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this user?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var component = this
                var parms = {id: id, contract_id: this.state.data.rec.id}
                global.postFetch("contracts/deleteuser", parms, component.state.access_token).then(resp => {
                    this.notifySuccess("User has been removed from Contract")
                    this.loadRecord()
                })
            }
        })
    }
    DeleteEncumbrance = (id) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this encumbrance?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var component = this
                var parms = {id: id, contract_id: this.state.data.rec.id}
                global.getFetch("contracts/deleteencumbrance", parms, component.state.access_token).then(resp => {
                    this.notifySuccess("Encumbrance has been removed from Contract")
                    this.loadRecord()


                })
            }
        })
    }
    DeleteExpense = (id) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this expense?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var component = this
                var parms = {id: id, contract_id: this.state.data.rec.id}
                global.getFetch("contracts/deleteexpense", parms, component.state.access_token).then(resp => {
                    this.notifySuccess("Expense has been removed from Contract")
                    this.loadRecord()


                })
            }
        })
    }
    DeleteExpense = (id) => {
        confirmAlert({
            title: 'Confirm Delete',                        // Title dialog
            message: 'Are you sure you want to delete this expense?',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var component = this
                var parms = {id: id, contract_id: this.state.data.rec.id}
                global.getFetch("contracts/deleteexpense", parms, component.state.access_token).then(resp => {
                    this.notifySuccess("Expense has been removed from Contract")
                    this.loadRecord()


                })
            }
        })
    }
    onNumberValueChange = (v) => {

    }
    NumberChange = (a, b, c) => {
        var data = this.state.data
        data.rec[c.name] = a
        var addl_data = this.state.addl_data
        addl_data[c.name] = a
        this.setState({data: data, addl_data: addl_data})

    }
    NumberChangeTable = (a, b, c) => {
        var split = c.name.split(".");
        var data = this.state.data
        data.rec.fee_for_service[split[1]][split[2]] = a
        this.setState({data: data})


    }
    NumberFieldUpdate = (e) => {
        var addldata = this.state.addl_data
        addldata[e.target.name] = e.target.value
        this.setState({addl_data: addldata})


    }

    formatCommas = (val) => {
        var str = val
        return (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, function (a, b, c) {
            return (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".") ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,") : b) + c;
        });
    }
    handleRouteSort = (newarray, a, b) => {

    }
    removeCommas = (val) => {
        return parseFloat(val.replace(/,/g, ''));
    }
    ChangeMandate = (e, {value}) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }

        var addldata = this.state.addl_data
        addldata.mandate_classification = value
        this.setState({mandate_classification: value, addl_data: addldata})
    }
    ChangeSignature = (e, {value}) => {
        this.setState({contract_approval: value})
    }
    ChangeOFrequency = (e, {value}) => {
        var objective = this.state.objective
        objective.frequency = value
        this.setState({objective: objective})
    }
    ChangeApproval = (e, {value}) => {
        var routing = this.state.route
        routing.signatory = value
        this.setState({route: routing,})
    }
    createMarkup = (str) => {
        return {__html: str};
    };
    startRouting = () => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }
        confirmAlert({
                title: 'Confirm',                        // Title dialog
                message: 'Are you sure you want to start routing?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {
                    var parms = {id: this.state.data.rec.id}

                    global.getFetch("contracts/start_routing", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess("Approval Routing Started")
                            this.loadRecord()
                        } else {
                            this.notifyError(resp.message)
                        }
                    })
                }
            }
        )
    }
    resetRouting = () => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }
        confirmAlert({
                title: 'Confirm',                        // Title dialog
                message: 'Are you sure you want to reset routing?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {
                    var parms = {id: this.state.data.rec.id}

                    global.getFetch("contracts/reset_routing", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess("Approval Routing Reset")
                            this.loadRecord()
                        } else {
                            this.notifyError(resp.message)

                        }
                    })
                }
            }
        )
    }
    copyRouting = () => {
        this.setState({showcopymodal: true})
    }
    addFee = () => {
        var data = this.state.data
        data.rec.fee_for_service.push({id: null, desc: "", amount: 0, units: 0, uom: ""})
        this.setState({data: data})

    }
    removeFee = (idx) => {
        var data = this.state.data
        data.rec.fee_for_service[idx]["_destroy"] = "1"
        this.setState({data: data})

    }
    handlecopyclose = () => {
        this.setState({showcopymodal: false})
    }
    newObjective = () => {
        var objective = this.state.objective
        objective.id = null
        objective.frequency = "M"
        objective.description = ""
        this.setState({objective: objective, showobjectivemodal: true})

    }
    handleroutingclose = () => {
        this.setState({showroutingmodal: false})
    }
    handleobjectiveclose = () => {
        this.setState({showobjectivemodal: false})
    }
    handledocimportsclose = () => {
        this.setState({showdocsmodal: false})
    }
    searchOrganizations = (e, data) => {
        if (data.searchQuery.length >= 3) {
            var component = this
            var parms = {keywords: data.searchQuery}
            global.getFetch("common/getorganizations", parms, component.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    component.setState({
                        orgoptions: resp.organizations
                    })
                }
            })
        }
    }
    searchUsers = (e, data) => {
        if (data.searchQuery.length >= 3) {
            var component = this
            var parms = {keywords: data.searchQuery}
            global.getFetch("common/getusers", parms, component.state.access_token).then(function (resp) {
                if (resp.success === "Y") {
                    component.setState({
                        accessusers: resp.users
                    })
                }
            })
        }
    }
    drawRouting = ({value, index, onRemove, onChange, decorateHandle}) => {
        return (
            <div>
                {decorateHandle(<span style={{cursor: 'move'}}>+</span>)}
                {' '}
                <span onClick={onRemove} style={{cursor: 'pointer'}}>X</span>
                {' '}
                {value.email}
            </div>
        )
    }
    loadBoilerplate = (e, data) => {
        if (this.state.data.rec.locked) {
            this.notifyError("Contract is locked. No changes allowed.")
            return;
        }
        if (this.template_data !== "") {
            confirmAlert({
                    title: 'Confirm Change',                        // Title dialog
                    message: 'Are you sure you want to change this template? Any changes will be lost',               // Message dialog
                    confirmLabel: 'Yes',                           // Text button confirm
                    cancelLabel: 'No',                             // Text button cancel
                    onConfirm: () => {

                        var parms = {id: data.value}
                        global.getFetch("common/loadboilerplate", parms, this.state.access_token).then(resp => {
                            if (resp.success === "Y") {
                                var data = this.state.data
                                data.rec.template = resp.template
                                this.template_data = resp.template
                                this.setState({
                                    data: data
                                })
                            }
                        })

                    }
                }
            )
            return
        }
        var parms = {id: data.value}
        global.getFetch("common/loadboilerplate", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                var data = this.state.data
                data.rec.template = resp.template
                this.template_data = resp.template
                this.setState({
                    data: data
                })
            }
        })
    }
    handleTemplateEditorChange = (e) => {
        var vals = []
        this.template_data = e
    }
    handleScheduleAChange = (e) => {
        var data = this.state.data
        data.rec.schedule_a_text = e
        this.setState({data: data})
    }
    handleScheduleBChange = (e) => {
        var data = this.state.data
        data.rec.schedule_b_text = e
        this.setState({data: data})
    }
    previewPDF = () => {
        var parms = {
            html: this.template_data
        }
        global.postFetch("render_pdf", parms, this.state.access_token).then(function (resp) {
            global.openLink(resp.pdf)
        })
    }
    importInsurance = () => {
        this.setState({showinsuranceimport: true})
    }
    ApproveContract = () => {
        this.setState({showdimmer: true})
        var parms = {
            user_id: this.state.data.rec.waiting_id,
            contract_id: this.state.data.rec.id
        }
        global.getFetch("contracts/get_next_step", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.setState({showdimmer: false})

                this.setState({showapproval: true, current_step: resp.route, route_steps: resp.route_steps})
            }
        })

    }
    handleinsuranceclose = () => {
        this.setState({showinsuranceimport: false})
    }
    handleapprovalclose = () => {
        this.setState({showapproval: false})
    }
    handleimportclose = () => {
        this.setState({showdocsimport: false})
    }
    handleAmend = () => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to AMEND this contract? A new contract will be created with all the details of this contract.',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,

                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/amend_contract", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        var parms = {
                            id: resp.rec.id
                        }
                        var component = this
                        global.getFetch("contracts/read", parms, component.state.access_token).then(function (resp) {
                            component.setState({
                                data: resp,
                                recid: resp.rec.id,
                                randomkeyform: Math.random()


                            });
                            component.notifySuccess("Contract has been AMENDED")
                            component.setState({showdimmer: false})
                        })

                    } else {
                        this.notifyError(resp.message)
                    }


                })
            }
        })
    }
    handleClone = () => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to CLONE this contract? An EXACT DUPLICATE contract will be created with all the details of this contract.',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,

                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/clone_contract", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        var parms = {
                            id: resp.rec.id
                        }
                        var component = this
                        global.getFetch("contracts/read", parms, component.state.access_token).then(function (resp) {
                            component.setState({
                                data: resp,
                                recid: resp.rec.id

                            });
                            component.notifySuccess("Contract has been CLONED")
                            component.setState({showdimmer: false})
                        })

                    } else {
                        this.notifyError(resp.message)
                    }


                })
            }
        })
    }

    handleClone = () => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to CLONE this contract? An EXACT DUPLICATE contract will be created with all the details of this contract.',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,

                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/clone_contract", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        var parms = {
                            id: resp.rec.id
                        }
                        var component = this
                        global.getFetch("contracts/read", parms, component.state.access_token).then(function (resp) {
                            component.setState({
                                data: resp,
                                recid: resp.rec.id

                            });
                            component.notifySuccess("Contract has been CLONED")
                            component.setState({showdimmer: false})
                        })

                    } else {
                        this.notifyError(resp.message)
                    }


                })
            }
        })
    }
    uomChange = (e, fdata) => {
        var split = fdata.name.split(".");
        var data = this.state.data
        data.rec.fee_for_service[split[1]][split[2]] = fdata.value
        this.setState({data: data})

    }
    loadDefaultRoute = (e, data) => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to update this contract with the default routing selected.',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,
                    default_route: data.value

                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/use_default_routing", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        this.setState({showdimmer: false})
                        this.notifySuccess(resp.message)
                        this.loadRecord()
                    } else {
                        this.notifyError(resp.message)
                    }


                })
            }
        })
    }


    handleRenew = () => {
        confirmAlert({
            title: 'Confirm',                        // Title dialog
            message: 'Are you sure you want to RENEW this contract? A new contract will be created with all the details of this contract.',               // Message dialog
            confirmLabel: 'Yes',                           // Text button confirm
            cancelLabel: 'No',                             // Text button cancel
            onConfirm: () => {

                var parms = {
                    id: this.state.data.rec.id,

                }
                this.setState({showdimmer: true})
                global.postFetch("contracts/renew_contract", parms, this.state.access_token).then(resp => {
                    if (resp.success === "Y") {
                        var parms = {
                            id: resp.rec.id
                        }
                        var component = this
                        global.getFetch("contracts/read", parms, component.state.access_token).then(function (resp) {
                            component.setState({
                                data: resp,
                                recid: resp.rec.id,
                                randomkeyform: Math.random()


                            });
                            component.notifySuccess("Contract has been RENEWED")
                            component.setState({showdimmer: false})
                        })

                    } else {
                        this.notifyError(resp.message)
                    }


                })
            }
        })
    }
    handlerenewclose = () => {
        this.setState({showrenewmodal: false})
    }
    handleamendclose = () => {
        this.setState({showamendmodal: false})
    }
    AddEncumbrance = () => {
        var parms = {
            contract_id: this.state.recid,
            enc_date: this.refs.enc_date.input.value,
            enc_account: this.refs.enc_account.inputRef.value,
            enc_amount: this.refs.enc_amount.state.numAsString,
            enc_desc: this.refs.enc_desc.inputRef.value


        }
        global.postFetch("contracts/add_encumbrance", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.notifySuccess("Encumbrance Added")
                this.refs.enc_date.mask.setValue("")

                this.refs.enc_account.inputRef.value = ""
                this.refs.enc_desc.inputRef.value = ""
                this.refs.enc_amount.state.numAsString = ""
                this.setState({redraw: Math.random()})
                this.loadRecord()
            } else {
                this.notifyError(resp.message)

            }
        })
    }
    AddExpense = () => {
        var parms = {
            contract_id: this.state.recid,
            exp_date: this.refs.exp_date.input.value,
            exp_account: this.refs.exp_account.inputRef.value,
            exp_amount: this.refs.exp_amount.state.numAsString,
            exp_desc: this.refs.exp_desc.inputRef.value


        }
        global.postFetch("contracts/add_expense", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.notifySuccess("Expense Added")
                this.refs.exp_date.mask.setValue("")

                this.refs.exp_account.inputRef.value = ""
                this.refs.exp_desc.inputRef.value = ""
                this.refs.exp_amount.state.numAsString = ""
                this.setState({redraw: Math.random()})
                this.loadRecord()
            } else {
                this.notifyError(resp.message)

            }
        })
    }
    refreshctable = () => {
        this.refs.searchcform.reset()
        this.setState({randomkeyccr: Math.random(), searchkey: ""})
    }
    fetchCData = (state, instance) => {
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        var obj = JSON.parse(sessionStorage.getItem("current_user"));
        this.setState({cloading: true});
        var srts = ""
        state.sorted.map(function (i) {
            srts += (srts === "" ? "" : ",") + (!i.id.includes(".") ? "contracts." : "") + i.id + (i.desc ? " desc" : " asc")
            return srts
        })
        if (srts === "") {
            srts = "contracts.id desc"
        }
        var component = this;
        var parms = {
            pageSize: state.pageSize,
            page: state.page + 1,
            sorted: srts,
            search: this.state.searchkey,
            uid: obj.id,
            id: this.state.data.rec.id

        }

        global.getFetch("contracts/croute", parms, this.state.access_token).then(function (resp) {
            component.setState({
                cdata: resp.rows,
                cpages: resp.pages,
                cloading: false,
                currentpage: state.page + 1,
                parms: parms,
                sorted: srts,
                pageSize: state.pageSize,
                itypes: resp.itypes
            });
        })
    }
    ImportVendorInsurance = (ri) => {
        var parms = {id: ri.row.id, contract_id: this.state.recid}

        global.getFetch("contracts/importinsurance", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.notifySuccess("Insurance Document Imported")
                var data = this.state.data
                data.rec.contract_insurances = resp.contract_insurances
                data.rec.vendor_insurances = resp.vendor_insurances
                this.setState({
                    data: data
                })
            }
        })

    }
    importDocs = () => {
        this.setState({showdocsimport: true})
    }
    ImportVendorDoc = (ri) => {
        var parms = {id: ri.row.id, contract_id: this.state.recid}
        global.getFetch("contracts/importvendordoc", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.notifySuccess("Vendor Documents Imported")
                var data = this.state.data
                data.rec.vendor_documents = resp.vendor_documents
                data.rec.attachments = resp.attachments
                this.setState({
                    data: data
                })
            }
        })

    }
    DeleteInsuranceDocument = (ri) => {

        confirmAlert({
                title: 'Confirm Delete',                        // Title dialog
                message: 'Are you sure you want to delete this document?',               // Message dialog
                confirmLabel: 'Yes',                           // Text button confirm
                cancelLabel: 'No',                             // Text button cancel
                onConfirm: () => {

                    var parms = {id: ri.row.id, contract_id: this.state.recid}
                    global.getFetch("contracts/deleteinsurance", parms, this.state.access_token).then(resp => {
                        if (resp.success === "Y") {
                            this.notifySuccess("Insurance Document Deleted")
                            var data = this.state.data
                            data.rec.contract_insurances = resp.contract_insurances
                            data.rec.vendor_insurances = resp.vendor_insurances
                            this.setState({
                                data: data
                            })
                        }
                    })
                }
            }
        )
    }
    ChangeInsurance = (row, type, value) => {


        var parms = {type: type, id: row, value: value}
        var comp = this
        global.postFetch("contracts/update_insurance", parms, this.state.access_token).then(resp => {
            if (resp.success === "Y") {
                this.loadRecord()
            } else {
                comp.notifyError(resp.message)
            }
        })

    }
    renderCoverage = (cellInfo) => {
        return (
            <NumericInput
                value={this.state.data.rec.insurance_requirements[cellInfo.index][cellInfo.column.id]}
                min={0}
                max={99999999}
                precision={0}
                strict
                style={{textAlign: "right", height: "10px"}}
                onBlur={(event) => {
                    this.ChangeInsurance(cellInfo.row.id, "coverage", event.target.value)

                }}
                format={this.formatCommas}
                parse={this.removeCommas}

            />

        );
    }
    renderAggregate = (cellInfo) => {
        return (
            <NumericInput
                value={this.state.data.rec.insurance_requirements[cellInfo.index][cellInfo.column.id]}
                min={0}
                max={99999999}
                precision={0}
                strict
                style={{textAlign: "right"}}
                onBlur={(event) => {
                    this.ChangeInsurance(cellInfo.row.id, "aggregate", event.target.value)

                }}
                format={this.formatCommas}
                parse={this.removeCommas}

            />

        );
    }
    renderUmbrella = (cellInfo) => {
        return (
            <NumericInput
                value={this.state.data.rec.insurance_requirements[cellInfo.index][cellInfo.column.id]}
                min={0}
                max={99999999}
                precision={0}
                strict
                style={{textAlign: "right"}}
                onBlur={(event) => {
                    this.ChangeInsurance(cellInfo.row.id, "umbrella", event.target.value)

                }}
                format={this.formatCommas}
                parse={this.removeCommas}

            />

        );
    }

    render() {
        const uom = [
            {
                text: 'Hour',
                value: 'H',

            }, {text: "Day", value: "D"}, {text: "Week", value: "W"}, {text: "Month", value: "M"}, {
                text: "Year",
                value: "Y"
            }];
        const {activeIndex, randomkeycd, cpages, cloading, randomkey3x, randomkeyccr, randomkey2, randomkeypo, randomkey3, randomkey4, randomkeye, randomkeyom, randomkey, current_user, cdata, route_steps} = this.state
        var enc_total = 0.00
        var exp_total = 0.00
        var client = window.location.host.split(".")[0]        
        var soptions = [{text: "", value: "none"}, {text: "County Manager/Administrator", value: "cmanager"},
            {text: "County Attorney", value: "cattorney"},
            {text: "Vendor", value: "vsig"}
        ]
        if(client === 'albany' || client === 'localhost:3000') {
            soptions = [{text: "", value: "none"}, {text: "County Executive", value: "cmanager"},
                {text: "County Attorney", value: "cattorney"},
                {text: "Vendor", value: "vsig"}
            ]
        }
        this.state.data.rec.contract_encumbrances.map(d => {
            enc_total += parseFloat(d.amount)
        })
        this.state.data.rec.contract_expenses.map(d => {
            exp_total += parseFloat(d.amount)
        })
        var possible_users = []
        if (this.state.data.contract_users) {
            this.state.data.contract_users.map(function (d) {
                possible_users.push({value: d.user_id, text: d.email})

            })
        }
        var f = 0
        var finpanes = []
        if (current_user.can_track_encumbrances) {
            finpanes[f] =
                {
                    menuItem: <Menu.Item key="ft1"
                                         className={this.state.recid == null ? "hiddenTab" : ""}>Encumbrances</Menu.Item>,
                    pane: (
                        <Tab.Pane key='ft11'>
                            {current_user.can_create_encumbrances ?
                                <Grid padded={false}>

                                    <Grid.Row style={{paddingBottom: "0", marginBottom: "0"}}>
                                        <Grid.Column style={{textAlign: "center", width: "120px"}}>
                                            <b><u>Date</u></b>
                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "left", width: "210px"}}>
                                            <b><u>Account</u></b>
                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "left", width: "440px"}}>
                                            <b><u>Description</u></b>
                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "right", width: "135px"}}>
                                            <b><u>Amount</u></b>
                                        </Grid.Column>
                                        <Grid.Column style={{width: "125px"}}></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "2"}}>
                                        <Grid.Column style={{textAlign: "center", width: "120px"}}>
                                            <MaskedInput style={{width: "110px"}}
                                                         ref="enc_date"
                                                         mask="##/##/####"

                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "left", width: "210px"}}>
                                            <Input style={{width: "200px"}} ref="enc_account" defaultValue=""


                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "left", width: "440px"}}>
                                            <Input style={{width: "435px"}} ref="enc_desc" defaultValue=""

                                            />

                                        </Grid.Column>
                                        <Grid.Column style={{textAlign: "right", width: "135px"}}>
                                            <NumberFormat
                                                style={{width: "125px", textAlign: "right"}}
                                                ref="enc_amount"
                                                displayType={'input'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}/>
                                        </Grid.Column>
                                        <Grid.Column style={{width: "125px"}}> <Button size="tiny" color="green"
                                                                                       onClick={this.AddEncumbrance}>
                                            <Icon name='save'/> Save
                                        </Button></Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "2"}}>
                                        <Grid.Column style={{width: "980px", textAlign: "right"}}>
                                            <b>Total Encumbrances: </b><NumberFormat
                                            style={{width: "125px", textAlign: "right"}}
                                            value={enc_total}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid> :
                                <Grid padded={false} style={{paddingTop: "0", marginTop: "0", paddingBottom: "15"}}>
                                    <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "5"}}>
                                        <Grid.Column style={{width: "980px", textAlign: "right"}}>
                                            <b>Total Encumbrances: </b><NumberFormat
                                            style={{width: "125px", textAlign: "right"}}
                                            value={enc_total}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>}
                            <ReactTable
                                key={randomkeye}

                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onDoubleClick: (e, handleOriginal) => {
                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.

                                            if (column.Header === "D") {
                                                this.DeleteEncumbrance(rowInfo.row.id)
                                            }

                                        }
                                    }
                                }}
                                columns={[


                                    {
                                        Header: "Id",
                                        accessor: "id",
                                        width: 0,
                                        style: {display: "none"}
                                    },
                                    current_user.can_delete_encumbrances ?

                                        {
                                            Header: "D",
                                            Cell: row => (
                                                <div>
                                                    <Icon name='delete'/>
                                                </div>),
                                            width: 30
                                        } :
                                        {
                                            Header: "",
                                            width: 1
                                        }
                                    ,

                                    {
                                        Header: "Date",
                                        accessor: "enc_date",
                                        width: 125,
                                        Cell: row => (
                                            <div>
                                                {row.value === null ? " " :
                                                    <Moment format="MM/DD/YYYY">
                                                        {row.value}
                                                    </Moment>
                                                }
                                            </div>
                                        )
                                    },
                                    {
                                        Header: "Account",
                                        accessor: "account",
                                        width: 260
                                    }
                                    ,
                                    {
                                        Header: "Description",
                                        accessor: "description",
                                        width: 410
                                    }
                                    ,
                                    {
                                        Header: "Amount",
                                        accessor: "amount",
                                        width: 135,
                                        style: {textAlign: "right"},

                                        Cell: row => (
                                            <NumberFormat
                                                style={{width: "125px", textAlign: "right"}}
                                                value={row.value}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}/>

                                        )
                                    }
                                ]}
                                showPaginationTop
                                data={this.state.data.rec.contract_encumbrances}
                                className="-striped -highlight"
                            />
                        </Tab.Pane>
                    )
                }
            f += 1
        }
        if (current_user.can_track_expenses) {
            finpanes[f] =
                {
                    menuItem: <Menu.Item key="ft2"
                                         className={this.state.recid == null ? "hiddenTab" : ""}>Expenses</Menu.Item>,
                    pane:
                        (
                            <Tab.Pane key='ft21'>
                                {current_user.can_create_expenses ?

                                    <Grid padded={false}>

                                        <Grid.Row style={{paddingBottom: "0", marginBottom: "0"}}>
                                            <Grid.Column style={{textAlign: "center", width: "120px"}}>
                                                <b><u>Date</u></b>
                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "left", width: "210px"}}>
                                                <b><u>Account</u></b>
                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "left", width: "440px"}}>
                                                <b><u>Description</u></b>
                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "right", width: "135px"}}>
                                                <b><u>Amount</u></b>
                                            </Grid.Column>
                                            <Grid.Column style={{width: "125px"}}></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "2"}}>
                                            <Grid.Column style={{textAlign: "center", width: "120px"}}>
                                                <MaskedInput style={{width: "110px"}}
                                                             ref="exp_date"
                                                             mask="##/##/####"

                                                />
                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "left", width: "210px"}}>
                                                <Input style={{width: "200px"}} ref="exp_account" defaultValue=""


                                                />
                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "left", width: "440px"}}>
                                                <Input style={{width: "435px"}} ref="exp_desc" defaultValue=""

                                                />

                                            </Grid.Column>
                                            <Grid.Column style={{textAlign: "right", width: "135px"}}>
                                                <NumberFormat
                                                    style={{width: "125px", textAlign: "right"}}
                                                    ref="exp_amount"
                                                    displayType={'input'}
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}/>
                                            </Grid.Column>
                                            <Grid.Column style={{width: "125px"}}> <Button size="tiny" color="green"
                                                                                           onClick={this.AddExpense}>
                                                <Icon name='save'/> Save
                                            </Button></Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "2"}}>
                                            <Grid.Column style={{width: "980px", textAlign: "right"}}>
                                                <b>Total Expenses: </b><NumberFormat
                                                style={{width: "125px", textAlign: "right"}}
                                                value={exp_total}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> :
                                    <Grid padded={false} style={{paddingTop: "0", marginTop: "0", paddingBottom: "15"}}>
                                        <Grid.Row style={{paddingTop: "0", marginTop: "0", paddingBottom: "5"}}>
                                            <Grid.Column style={{width: "980px", textAlign: "right"}}>
                                                <b>Total Expenses: </b><NumberFormat
                                                style={{width: "125px", textAlign: "right"}}
                                                value={exp_total}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}/>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>}
                                <ReactTable
                                    key={randomkeye}

                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            onDoubleClick: (e, handleOriginal) => {
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.

                                                if (column.Header === "D") {
                                                    this.DeleteExpense(rowInfo.row.id)
                                                }

                                            }
                                        }
                                    }}
                                    columns={[


                                        {
                                            Header: "Id",
                                            accessor: "id",
                                            width: 0,
                                            style: {display: "none"}
                                        },
                                        current_user.can_delete_expenses ?

                                            {
                                                Header: "D",
                                                Cell: row => (
                                                    <div>
                                                        <Icon name='delete'/>
                                                    </div>),
                                                width: 30
                                            } :
                                            {
                                                Header: "",
                                                width: 1
                                            }
                                        ,
                                        {
                                            Header: "Date",
                                            accessor: "enc_date",
                                            width: 125,
                                            Cell: row => (
                                                <div>
                                                    {row.value === null ? " " :
                                                        <Moment format="MM/DD/YYYY">
                                                            {row.value}
                                                        </Moment>
                                                    }
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Account",
                                            accessor: "account",
                                            width: 260
                                        }
                                        ,
                                        {
                                            Header: "Description",
                                            accessor: "description",
                                            width: 410
                                        }
                                        ,
                                        {
                                            Header: "Amount",
                                            accessor: "amount",
                                            width: 135,

                                            Cell: row => (
                                                <NumberFormat
                                                    style={{width: "125px", textAlign: "right"}}
                                                    value={row.value}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}/>

                                            )
                                        }
                                    ]}
                                    showPaginationTop
                                    data={this.state.data.rec.contract_expenses}
                                    className="-striped -highlight"
                                />
                            </Tab.Pane>
                        )
                }
            f += 1
        }
        var docpanes = []
        var dp = 0
        if (current_user.can_access_document_templates) {

            docpanes[dp] = {
                menuItem: <Menu.Item key="d1"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Template</Menu.Item>,
                pane: (
                    <Tab.Pane key='d11xx'>

                        <Menu>
                            <Menu.Item>
                                <Button size="small" color="green" onClick={this.generateContractDocument}>
                                    <Icon name='save'/> Generate Contract
                                </Button>
                            </Menu.Item>
                            <Menu.Item position='right'>
                                <Button size="small" onClick={this.previewPDF}>
                                    <Icon name='file pdf outline'/> Preview Template
                                </Button>
                            </Menu.Item>
                        </Menu><br/>
                        <Form.Input
                            name="contract_doc_name"
                            size="small"
                            label="Contract Document Name"
                            placeholder="Contract Document Name"
                            defaultValue={this.state.data.rec.contract_doc_name}
                        />
                        <label><b>Select Template</b>&nbsp;</label>

                        <Form.Dropdown
                            fluid
                            selection
                            search={true}
                            multiple={false}
                            placeholder='Select Template'
                            name="boilerplate_id"
                            onChange={this.loadBoilerplate}
                            options={this.state.data.rec.boilerplates}
                            selectOnBlur={false}
                            selectOnNavigation={true}


                        />
                        <CKEditor
                            value={this.template_data}
                            name="template"
                            onChange={this.handleTemplateEditorChange}

                            config={{
                                height: 600,
                                availableTokens:
                                this.state.columns,
                                autoParagraph: false


                            }}/>
                    </Tab.Pane>
                )
            }
            dp += 1
        }
        if (this.state.data.rec.schedule_a) {

            docpanes[dp] = {
                menuItem: <Menu.Item key="dscheda1"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Schedule A</Menu.Item>,
                pane: (
                    <Tab.Pane key='dscheda11'>
                        <DropZone className="dropzone" ref="dropzone" multiple={true} onDrop={this.onAttachmentADrop}>
                            Drag file(s) here or click to browse.
                        </DropZone> <br/>
                        <ReactTable
                            key={randomkey}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "P") {
                                            this.showDocument(rowInfo)
                                        }
                                        if (column.Header === "D") {
                                            this.DeleteDocument(rowInfo.row.id)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },

                                {
                                    Header: "D",
                                    Cell: row => (
                                        <div>
                                            <Icon name='delete'/>
                                        </div>),
                                    width: 30
                                }
                                ,

                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 0
                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            data={this.state.data.rec.schedule_a_docs}
                            className="-striped -highlight"
                            defaultPageSize={5}

                        />
                        <CKEditor
                            value={this.state.data.rec.schedule_a_text}
                            name="template"
                            onChange={this.handleScheduleAChange}
                            style={{marginTop: "10px"}}

                            config={{
                                height: 600,
                                availableTokens:
                                this.state.columns,
                                autoParagraph: false


                            }}/>

                    </Tab.Pane>
                )
            }
            dp += 1
        }

        if (current_user.can_access_document_contract) {

            docpanes[dp] = {
                menuItem: <Menu.Item key="d1a1"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Contract</Menu.Item>,
                pane: (
                    <Tab.Pane key='d11'>
                        <span style={{color: "green", fontWeight: "bold"}}>* Current Contract Version</span>
                        <ReactTable
                            key={randomkeycd}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {

                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "P") {
                                            this.showDocument(rowInfo)
                                        }
                                        if (column.Header === "D") {
                                            this.DeleteDocument(rowInfo.row.id)
                                        }

                                    }


                                }
                            }}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        background: rowInfo && (rowInfo.row.subtype === "C") ? 'green' : '',
                                        color: rowInfo && (rowInfo.row.subtype === "C") ? 'white' : 'black'
                                    },


                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },
                                current_user.can_delete_contract_document ?
                                    {
                                        Header: "D",
                                        Cell: row => (
                                            <div>
                                                <Icon name='delete'/>
                                            </div>),
                                        width: 30
                                    } : {
                                        Header: "",

                                        width: 0
                                    }
                                ,

                                {
                                    Header: "",
                                    accessor: "subtype",
                                    width: 0,
                                    style: {display: "none"},
                                },
                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 60,
                                    style: {display: "none"}
                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY hh:mm:ss A">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            pageSize={5}
                            data={this.state.data.rec.contract_documents}
                            className="-striped -highlight"
                        />

                    </Tab.Pane>
                )
            }
            dp += 1
        }
        if (current_user.can_access_document_insurance) {
            docpanes[dp] = {
                menuItem: <Menu.Item key="d2"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Insurance</Menu.Item>,
                pane: (
                    <Tab.Pane key='d12'>
                        {current_user.can_set_insurance_limits ?
                            <Segment raised>


                                <ReactTable
                                    key={randomkey3x}

                                    columns={[


                                        {
                                            Header: "Id",
                                            accessor: "id",
                                            width: 0,
                                            style: {display: "none"}
                                        },
                                        {
                                            Header: "Type",
                                            accessor: "insurance_type",
                                            width: 250,

                                        },
                                        {
                                            Header: "Per Occurrence",
                                            accessor: "per",
                                            style: {textAlign: "right"},
                                            Cell: this.renderCoverage

                                        },
                                        {
                                            Header: "Aggregate",
                                            accessor: "aggregate",
                                            style: {textAlign: "right"},
                                            Cell: this.renderAggregate


                                        },
                                        {
                                            Header: "Umbrella",
                                            accessor: "umbrella",
                                            style: {textAlign: "right"},
                                            Cell: this.renderUmbrella


                                        },
                                    ]}
                                    showPaginationTop
                                    data={this.state.data.rec.insurance_requirements}
                                    pageSize={5}
                                    className="-striped -highlight"
                                /></Segment> : ""}
                        <Segment raised>
                            {current_user.can_import_contract_insurance ?
                                <div><Button size="tiny" color="green" onClick={this.importInsurance}>
                                    <Icon name='save'/> Import Insurance
                                </Button>&nbsp;<Button size="tiny" color="green" onClick={this.importMatchingInsurance}>
                                    <Icon name='save'/> Import Matching Insurance
                                </Button></div> : ""}
                            <ReactTable
                                key={randomkey3}

                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onDoubleClick: (e, handleOriginal) => {
                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.
                                            if (column.Header === "P") {
                                                this.showContractInsuranceDocument(rowInfo)
                                            }
                                            if (column.Header === "D") {
                                                this.DeleteInsuranceDocument(rowInfo)
                                            }

                                        }
                                    }
                                }}
                                columns={[

                                    {
                                        Header: "P",
                                        Cell: row => (
                                            <div>
                                                <Icon name='file pdf outline'/>
                                            </div>),
                                        width: 30
                                    },
                                    current_user.can_delete_contract_insurance ?
                                        {
                                            Header: "D",
                                            Cell: row => (
                                                <div>
                                                    <Icon name='delete'/>
                                                </div>),
                                            width: 30
                                        } : {
                                            Header: "",

                                            width: 0
                                        },

                                    {
                                        Header: "Id",
                                        accessor: "id",
                                        width: 0,
                                        style: {display: "none"}
                                    },
                                    {
                                        Header: "Type",
                                        accessor: "insurance_type",
                                        width: 250,

                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status",
                                        width: 200,


                                    },
                                    {
                                        Header: "Per Occurrence",
                                        accessor: "coverage_per",

                                    },
                                    {
                                        Header: "Aggregate",
                                        accessor: "aggregate",

                                    },
                                    {
                                        Header: "Umbrella",
                                        accessor: "umbrella",

                                    },
                                    {
                                        Header: "Expiration",
                                        accessor: "expiration",
                                        Cell: row => (
                                            <div>
                                                {row.value === null ? " " :
                                                    <Moment format="MM/DD/YYYY">
                                                        {row.value}
                                                    </Moment>
                                                }
                                            </div>
                                        )

                                    },
                                    {
                                        Header: "Date Created",
                                        accessor: "created_at",
                                        Cell: row => (
                                            <div>
                                                {row.value === null ? " " :
                                                    <Moment format="MM/DD/YYYY">
                                                        {row.value}
                                                    </Moment>
                                                }
                                            </div>
                                        )
                                    }
                                ]}
                                showPaginationTop
                                data={this.state.data.rec.contract_insurances}
                                pageSize={5}
                                className="-striped -highlight"
                            /></Segment>
                    </Tab.Pane>
                )
            }
            dp += 1
        }
        if (current_user.can_access_document_attachments) {
            docpanes[dp] = {
                menuItem: <Menu.Item key="d3"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Attachments</Menu.Item>,
                pane: (
                    <Tab.Pane key='d13'>
                        <Button size="tiny" color="green" onClick={this.importDocs}>
                            <Icon name='save'/> Import Vendor Documents
                        </Button>
                        <DropZone className="dropzone" ref="dropzone" multiple={true} onDrop={this.onAttachmentDrop}>
                            Drag file(s) here or click to browse.
                        </DropZone> <br/>
                        <ReactTable
                            key={randomkey}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "P") {
                                            this.showDocument(rowInfo)
                                        }
                                        if (column.Header === "D") {
                                            this.DeleteDocument(rowInfo.row.id)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },

                                {
                                    Header: "D",
                                    Cell: row => (
                                        <div>
                                            <Icon name='delete'/>
                                        </div>),
                                    width: 30
                                }
                                ,
                                {
                                    Header: "Include?",
                                    accessor: "include",
                                    Cell: this.renderInclude,
                                    width: 80

                                },

                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 60
                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            data={this.state.data.rec.attachments}
                            className="-striped -highlight"
                        />
                    </Tab.Pane>
                )
            }
        }

        var panes = []
        var x = 0
        if (current_user.can_access_contract_overview_tab && this.state.recid !== null) {
            panes[x] =

                {
                    menuItem: <Menu.Item key="s1a"
                    >Overview</Menu.Item>,
                    pane: (
                        <Tab.Pane key='tab1a'>
                            <div>
                                <Grid padded={false} columns='equal'>

                                    <Grid.Row>
                                        <Grid.Column width={16}>

                                            <Segment raised>
                                                <Header as='h5' icon='file outline' content='Contract Details'
                                                        style={{paddingBottom: "15px"}}>

                                                </Header>
                                                <Grid padded={false} columns='equal'>

                                                    <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 0}}

                                                    >
                                                        <Grid.Column width={4}>
                                                            <b>Contract Number: </b>
                                                            {this.state.data.rec.contract_no}

                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <b>Contract Name: </b>
                                                            {this.state.data.rec.name}

                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            {this.state.data.rec.contract_document_id > 0 ?
                                                                <Button style={{float: "right"}}
                                                                        onClick={this.showContractDocument} size="tiny"
                                                                        color="green">
                                                                    <Icon name='save'/> View Contract Document
                                                                </Button> : ""}
                                                        </Grid.Column>


                                                    </Grid.Row>

                                                    <Grid.Row style={{paddingTop: "5"}}>
                                                        <Grid.Column width={8}>


                                                            <Grid padded={false} columns='equal'>
                                                                <Grid.Row><Grid.Column width={8}>
                                                                    <label><b>Start Date: </b>&nbsp;<span
                                                                        style={{color: 'red'}}>*</span></label>

                                                                    {this.state.data.rec.start_date}

                                                                </Grid.Column>
                                                                    <Grid.Column width={8}>
                                                                        <label><b>End Date: </b>&nbsp;<span
                                                                            style={{color: 'red'}}>*</span></label>

                                                                        {this.state.data.rec.end_date}
                                                                    </Grid.Column>
                                                                </Grid.Row>

                                                                <Grid.Row style={{paddingTop: "0"}}>

                                                                    <Grid.Column width={8}>
                                                                        <Segment raised style={{paddingTop: "3"}}>
                                                                            <Header icon='file outline' as='h5'
                                                                                    content='Class/Category'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <label><b>Classification</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.classification_d}<br/><br/>
                                                                            <label><b>Category</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.category_d}
                                                                        </Segment></Grid.Column>
                                                                    <Grid.Column width={8}>
                                                                        <Segment raised style={{paddingTop: "3"}}>
                                                                            <Header icon='file outline' as='h5'
                                                                                    content='Status/Priority'
                                                                                    style={{paddingBottom: "0px"}}/>

                                                                            <label><b>Status</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>

                                                                            {this.state.data.rec.status_d}<br/><br/>
                                                                            <label><b>Priority</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.priority_d}
                                                                        </Segment>

                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "0"}}>
                                                                    <Grid.Column width={8}>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='file outline'
                                                                                    content='This Contract'
                                                                                    style={{paddingBottom: "0px"}}/>

                                                                            <label><b>Not to Exceed Amt</b></label><br/>
                                                                            {this.state.data.rec.contract_ceiling}
                                                                            <br/> <br/>


                                                                            <label><b>Per Diem Rate</b></label><br/>

                                                                            <NumberFormat
                                                                                value={this.state.data.rec.per_diem_rate}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>
                                                                            <br/> <br/>
                                                                            <label><b>Grant Amount</b></label> <br/>

                                                                            <NumberFormat
                                                                                value={this.state.data.rec.grant_amount}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>
                                                                            <br/> <br/>
                                                                            <label><b>Units Expected</b></label> <br/>

                                                                            <NumberFormat
                                                                                value={this.state.data.rec.units}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}/>

                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={8}>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='file outline'
                                                                                    content='County Requirements'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.grant_funds}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="grant_funds" label="Grant Funds"/>
                                                                            <label><b>CFDA #</b></label><br/>
                                                                            {this.state.data.rec.grant_number}<br/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.budget_funds}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="budget_funds"
                                                                                label="Budgeted Funds"/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.baa}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="baa" label="BAA"/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.res_prepared}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="res_prepared" label="Resolution"/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.mwbe}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="mwbe" label="MWBE / DBE"/>
                                                                            <Checkbox
                                                                                defaultChecked={this.state.data.rec.dbe}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="dbe" label="Capital Project"/>

                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>


                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Grid padded={false} columns='equal'>

                                                                <Grid.Row style={{paddingTop: "45px"}}>
                                                                    <Grid.Column>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='user'
                                                                                    content='Department/Liaison Information'
                                                                                    style={{paddingBottom: "0px"}}/>

                                                                            <label><b>Department(s)</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.contract_depts_d}<br/><br/>

                                                                            <label><b>Liaison(s)</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.contract_lias_d}<br/><br/>

                                                                        </Segment>

                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "10px"}}>

                                                                    <Grid.Column>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='building outline'
                                                                                    content='Vendor Information'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <label><b>Vendor</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.organization_d}<br/><br/>

                                                                            <div
                                                                                dangerouslySetInnerHTML={this.createMarkup(this.state.orgaddress)}/>
                                                                            <br/>
                                                                            <label><b>Vendor Contact</b>&nbsp;
                                                                                <span
                                                                                    style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.organization_contact_d}
                                                                            <br/>
                                                                            <label><b>Vendor Signature</b>&nbsp;
                                                                                <span
                                                                                    style={{color: 'red'}}>*</span></label><br/>
                                                                            {this.state.data.rec.organization_signature_d}

                                                                        </Segment>

                                                                    </Grid.Column>


                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "10px"}}>
                                                                    <Grid.Column>
                                                                        {this.state.data.rec.schedule_a ?
                                                                            <Checkbox
                                                                                defaultChecked={true}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="schedule_a" label="Schedule A"/> :
                                                                            <Checkbox
                                                                                defaultChecked={false}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="schedule_a"
                                                                                label="Schedule A"/>} </Grid.Column>
                                                                    <Grid.Column>


                                                                    </Grid.Column>
                                                                    <Grid.Column>

                                                                        {this.state.data.rec.has_ceiling ?
                                                                            <Checkbox
                                                                                defaultChecked={true}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="has_ceiling" label="Inactive"/> :
                                                                            <Checkbox
                                                                                defaultChecked={false}
                                                                                toggle
                                                                                readOnly={true}
                                                                                name="has_ceiling" label="Inactive"/>}
                                                                    </Grid.Column>
                                                                </Grid.Row>

                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Accordion fluid styled>
                                                                <Accordion.Title active={activeIndex === 1} index={1}
                                                                                 onClick={this.handleAccordionClick}>
                                                                    <Icon name='dropdown'/>
                                                                    Fee for Service
                                                                </Accordion.Title>
                                                                <Accordion.Content active={activeIndex === 1}>
                                                                    <Grid padded={false} columns='equal'>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={8}>
                                                                                <b><u>Description</u></b>
                                                                            </Grid.Column>

                                                                            <Grid.Column width={8}
                                                                                         style={{textAlign: "right"}}>
                                                                                <b><u>Amount</u></b>
                                                                            </Grid.Column>


                                                                        </Grid.Row>

                                                                        {this.state.data.rec.fee_for_service.map((item, idx) => {
                                                                                if (this.state.data.rec.fee_for_service[idx]["_destroy"] !== "1") {
                                                                                    return (
                                                                                        <Grid.Row key={"row_" + idx}
                                                                                                  style={{
                                                                                                      paddingBottom: "0",
                                                                                                      paddingTop: "3px"
                                                                                                  }}>
                                                                                            <Grid.Column width={8}>
                                                                                                <b>{this.state.data.rec.fee_for_service[idx].desc}</b>

                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={8}
                                                                                                         style={{textAlign: "right"}}>
                                                                                                <b>{this.state.data.rec.fee_for_service[idx].amount}/{this.state.data.rec.fee_for_service[idx].uom}</b>

                                                                                            </Grid.Column>

                                                                                        </Grid.Row>

                                                                                    )
                                                                                }
                                                                            }
                                                                        )

                                                                        }
                                                                    </Grid>
                                                                </Accordion.Content></Accordion>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{paddingTop: "5px"}}>
                                                        <Grid.Column width={6}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Resolutions'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>
                                                                <Grid padded={false} columns='equal'>

                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            {this.state.data.rec.resolution_1}

                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            {this.state.data.rec.resolution_1_date}

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            {this.state.data.rec.resolution_2}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            {this.state.data.rec.resolution_2_date}

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            {this.state.data.rec.resolution_3}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            {this.state.data.rec.resolution_3_date}
                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "5px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            {this.state.data.rec.resolution_4}
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            {this.state.data.rec.resolution_4_date}
                                                                        </Grid.Column>


                                                                    </Grid.Row>


                                                                </Grid>

                                                            </Segment></Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Funding Sources'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>
                                                                <Grid padded={false} columns='equal'>

                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            <b>Federal %</b>

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}
                                                                                     style={{textAlign: "right"}}>
                                                                            <NumberFormat
                                                                                value={this.state.data.rec.perc_federal ? this.state.data.rec.perc_federal : 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>


                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            <b>State %</b>

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}
                                                                                     style={{textAlign: "right"}}>
                                                                            <NumberFormat
                                                                                value={this.state.data.rec.perc_state ? this.state.data.rec.perc_state : 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            <b>Local %</b>

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}
                                                                                     style={{textAlign: "right"}}>
                                                                            <NumberFormat
                                                                                value={this.state.data.rec.perc_local ? this.state.data.rec.perc_local : 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "5px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            <b>Other %</b>

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}
                                                                                     style={{textAlign: "right"}}>
                                                                            <NumberFormat
                                                                                value={this.state.data.rec.perc_other ? this.state.data.rec.perc_other : 0}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}/>


                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Mandate/Revenue'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>

                                                                <b>{this.state.mandate_classification === "mandated" ? "Mandated" : "Non-Mandated"}</b><br/>

                                                                <b> {this.state.data.rec.revenue_contract ? "Revenue Contract" : "Non-Revenue Contract"}</b>

                                                            </Segment>
                                                        </Grid.Column>

                                                    </Grid.Row>

                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>


                                    <Grid.Row style={{paddingTop: "0px"}}>
                                        <Grid.Column width={16}>
                                            <Segment raised>
                                                <b>WHEREAS</b>, the County desires to obtain services in connection with
                                                &nbsp;{this.state.data.rec.synopsis_of_program}


                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{paddingTop: "0px"}}>
                                        <Grid.Column width={16}>
                                            <Segment raised>
                                                <b>Need for Services</b><br/>
                                                {this.state.data.rec.need_for_services}
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                            </div>
                        </Tab.Pane>)

                }
            x += 1
        }
        if (current_user.can_access_cover_sheet_tab) {
            panes[x] = {
                menuItem: <Menu.Item key="s1">Cover Sheet</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab1'>
                            <div>
                                <Grid padded={false} columns='equal'>

                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Form.Input
                                                name="id"
                                                defaultValue={this.state.data.rec.id ? this.state.data.rec.id.toString() : null}
                                                style={{display: "none"}}
                                            />
                                            <Segment raised>
                                                <Header as='h5' icon='file outline' content='Contract Details'
                                                        style={{paddingBottom: "8px"}}>

                                                </Header>
                                                <Grid padded={false} columns='equal'>

                                                    <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 0}}

                                                    >
                                                        <Grid.Column width={4}>
                                                            <Form.Input
                                                                name="contract_no"
                                                                size="small"
                                                                label="Contract Number"
                                                                placeholder="Contract Number"
                                                                errorLabel={<Label color="red" pointing/>}
                                                                defaultValue={this.state.data.rec.contract_no}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column width={12}>
                                                            <Form.Input
                                                                required
                                                                name="name"
                                                                size="small"
                                                                label="Contract Name"
                                                                placeholder="Contract Name"
                                                                errorLabel={<Label color="red" pointing/>}
                                                                defaultValue={this.state.data.rec.name}
                                                                onChange={this.captureChange}
                                                                validationErrors={{
                                                                    isDefaultRequiredValue: 'Contract Name is Required',
                                                                }}/>
                                                        </Grid.Column>


                                                    </Grid.Row>

                                                    <Grid.Row style={{paddingTop: "5"}}>
                                                        <Grid.Column width={8}>


                                                            <Grid padded={false} columns='equal'>
                                                                <Grid.Row><Grid.Column width={8}>
                                                                    <label><b>Start Date</b>&nbsp;<span
                                                                        style={{color: 'red'}}>*</span></label>

                                                                    <MaskedInput name="start_date"
                                                                                 onChange={this.NumberFieldUpdate}
                                                                                 value={this.state.data.rec.start_date}
                                                                                 mask="##/##/####"
                                                                    />

                                                                </Grid.Column>
                                                                    <Grid.Column width={8}>
                                                                        <label><b>End Date</b>&nbsp;<span
                                                                            style={{color: 'red'}}>*</span></label>

                                                                        <MaskedInput name="end_date"
                                                                                     onChange={this.NumberFieldUpdate}
                                                                                     value={this.state.data.rec.end_date}
                                                                                     mask="##/##/####"
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>

                                                                <Grid.Row style={{paddingTop: "0"}}>

                                                                    <Grid.Column width={8}>
                                                                        <Segment raised style={{paddingTop: "3"}}>
                                                                            <Header as='h5' content='Class/Category'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <label><b>Classification</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            {current_user.can_update_contract_classification && !this.state.data.rec.locked ?
                                                                                <Form.Dropdown
                                                                                    fluid
                                                                                    selection
                                                                                    search={true}
                                                                                    multiple={false}
                                                                                    options={this.state.data.common.classes}
                                                                                    value={this.state.data.rec.classification}
                                                                                    placeholder='Select Classification'
                                                                                    name="classification"
                                                                                    defaultValue="new"
                                                                                /> :
                                                                                <div>{this.state.data.rec.classification_d}<br/><br/>
                                                                                </div>}
                                                                            <label><b>Category</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            {current_user.can_update_contract_category && !this.state.data.rec.locked ?

                                                                                <Form.Dropdown
                                                                                    fluid
                                                                                    selection
                                                                                    search={true}
                                                                                    multiple={false}
                                                                                    options={this.state.data.common.categories}
                                                                                    value={this.state.data.rec.category}
                                                                                    placeholder='Select Category'
                                                                                    name="category"
                                                                                /> :
                                                                                <div>{this.state.data.rec.category_d}<br/><br/>
                                                                                </div>}
                                                                        </Segment></Grid.Column>
                                                                    <Grid.Column width={8}>
                                                                        <Segment raised style={{paddingTop: "3"}}>
                                                                            <Header as='h5' content='Status/Priority'
                                                                                    style={{paddingBottom: "0px"}}/>

                                                                            <label><b>Status</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            {current_user.can_update_contract_status && !this.state.data.rec.locked ?

                                                                                <Form.Dropdown
                                                                                    fluid
                                                                                    selection
                                                                                    search={true}
                                                                                    multiple={false}
                                                                                    options={this.state.data.common.statuses}
                                                                                    value={this.state.data.rec.status}
                                                                                    placeholder='Select Status'
                                                                                    name="status"
                                                                                /> :
                                                                                <div>{this.state.data.rec.status_d}<br/><br/>
                                                                                </div>}
                                                                            <label><b>Priority</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            {current_user.can_update_contract_priority && !this.state.data.rec.locked ?


                                                                                <Form.Dropdown
                                                                                    fluid
                                                                                    selection
                                                                                    search={true}
                                                                                    multiple={false}
                                                                                    options={this.state.data.common.priorities}
                                                                                    value={this.state.data.rec.priority}
                                                                                    placeholder='Select Priority'
                                                                                    name="priority"
                                                                                    defaultValue="norm"
                                                                                /> :
                                                                                <div>{this.state.data.rec.priority_d}<br/><br/>
                                                                                </div>}
                                                                        </Segment>

                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "0"}}>
                                                                    <Grid.Column width={8}>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='file outline'
                                                                                    content='This Contract'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                                    <Form.Input
                                                                                name="contract_ceiling"
                                                                                size="small"
                                                                                label="Not to Exceed Amt"
                                                                                placeholder="Contract Ceiling"
                                                                                errorLabel={<Label color="red" pointing/>}
                                                                                defaultValue={this.state.data.rec.contract_ceiling}
                                                                            />
                                                                            <br/>
                                                                            <label><b>Per Diem Rate</b></label>
                                                                            <NumericInput
                                                                                defaultValue={this.state.data.rec.per_diem_rate}
                                                                                min={0}
                                                                                max={99999}
                                                                                precision={2}
                                                                                name="per_diem_rate"
                                                                                onChange={this.NumberChange}
                                                                                onFocus={this.handleFocus}
                                                                                strict
                                                                                noStyle

                                                                            /><br/>
                                                                            <label><b>Grant Amount</b></label>

                                                                            <NumericInput
                                                                                defaultValue={this.state.data.rec.grant_amount}
                                                                                min={0}
                                                                                max={9999999999}
                                                                                name="grant_amount"
                                                                                precision={2}
                                                                                onChange={this.NumberChange}
                                                                                onFocus={this.handleFocus}
                                                                                strict
                                                                                noStyle

                                                                            /><br/>
                                                                            <label><b>Units Expected</b></label>

                                                                            <NumericInput
                                                                                defaultValue={this.state.data.rec.units}
                                                                                min={0}
                                                                                max={9999999999}
                                                                                precision={0}
                                                                                name="units"
                                                                                onChange={this.NumberChange}
                                                                                onFocus={this.handleFocus}
                                                                                strict
                                                                                noStyle

                                                                            /><br/><br/><br/>
                                                                        </Segment>
                                                                    </Grid.Column>
                                                                    <Grid.Column width={8}>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='file outline'
                                                                                    content='County Requirements'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.grant_funds}
                                                                                toggle
                                                                                name="grant_funds" label="Grant Funds"/>
                                                                            <label><b>CFDA #</b></label><br/>
                                                                            <Form.Input
                                                                                name="grant_number"
                                                                                defaultValue={this.state.data.rec.grant_number ? this.state.data.rec.grant_number : null}
                                                                            />
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.budget_funds}
                                                                                toggle
                                                                                name="budget_funds"
                                                                                label="Budgeted Funds"/>
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.baa}
                                                                                toggle
                                                                                name="baa" label="BAA"/>
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.res_prepared}
                                                                                toggle
                                                                                name="res_prepared" label="Resolution"/>
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.mwbe}
                                                                                toggle
                                                                                name="mwbe" label="MWBE / DBE"/>
                                                                            <Form.Checkbox
                                                                                defaultChecked={this.state.data.rec.dbe}
                                                                                toggle
                                                                                name="dbe" label="Capital Project"/>


                                                                        </Segment>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>


                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Grid padded={false} columns='equal'>
                                                                <Grid.Row style={{paddingTop: "50px"}}>
                                                                    <Grid.Column>
                                                                        <Grid padded={false} columns='equal'>

                                                                            <Grid.Row style={{
                                                                                margin: 0,
                                                                                paddingTop: 0,
                                                                                paddingBottom: 5
                                                                            }}>
                                                                                <Grid.Column width={4}>
                                                                                    {current_user.can_unlock_contracts ?
                                                                                        <Form.Checkbox
                                                                                            defaultChecked={this.state.data.rec.locked}
                                                                                            toggle
                                                                                            name="locked"
                                                                                            label="Locked"/> : ""}</Grid.Column>
                                                                                <Grid.Column width={8}>
                                                                                    {current_user.can_designate_paper_contract && !this.state.data.rec.locked ?

                                                                                        <Form.Checkbox
                                                                                            style={{float: "right"}}
                                                                                            defaultChecked={this.state.data.rec.paper_contract}
                                                                                            toggle
                                                                                            name="paper_contract"
                                                                                            label="Paper Contract"/>
                                                                                        : <div></div>}
                                                                                </Grid.Column></Grid.Row></Grid>


                                                                    </Grid.Column></Grid.Row>
                                                                <Grid.Row style={{paddingTop: "22px"}}>
                                                                    <Grid.Column>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='user'
                                                                                    content='Department/Liaison Information'
                                                                                    style={{paddingBottom: "0px"}}/>

                                                                            <label><b>Department(s)</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            <Form.Dropdown
                                                                                fluid
                                                                                selection
                                                                                search={true}
                                                                                multiple={true}
                                                                                options={this.state.data.common.departments}
                                                                                value={this.state.data.rec.contract_depts}
                                                                                placeholder='Select Department'
                                                                                name="department_ids"
                                                                                onChange={this.getliaisons}
                                                                            />
                                                                            <label><b>Liaison(s)</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            <Dropdown
                                                                                fluid
                                                                                selection
                                                                                search={true}
                                                                                multiple={true}
                                                                                options={this.state.liaisons}
                                                                                value={this.state.data.rec.contract_lias}
                                                                                placeholder='Select Liaison(s)'
                                                                                name="liaison_ids"
                                                                            />
                                                                        </Segment>

                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "10px"}}>

                                                                    <Grid.Column>

                                                                        <Segment raised>
                                                                            <Header as='h5' icon='building outline'
                                                                                    content='Vendor Information'
                                                                                    style={{paddingBottom: "0px"}}/>
                                                                            <label><b>Vendor</b>&nbsp;<span
                                                                                style={{color: 'red'}}>*</span></label>
                                                                            <Form.Dropdown
                                                                                fluid
                                                                                selection
                                                                                search={true}
                                                                                multiple={false}
                                                                                options={this.state.orgoptions}
                                                                                value={this.state.data.rec.organization_id}
                                                                                placeholder='Select Organization'
                                                                                name="organization_id"
                                                                                onSearchChange={this.searchOrganizations}
                                                                                minCharacters={3}
                                                                                onChange={this.loadOrganization}

                                                                            />
                                                                            <div
                                                                                dangerouslySetInnerHTML={this.createMarkup(this.state.orgaddress)}/>
                                                                            <br/>
                                                                            <label><b>Vendor Contact</b>&nbsp;
                                                                                <span
                                                                                    style={{color: 'red'}}>*</span></label>
                                                                            <Form.Dropdown
                                                                                fluid
                                                                                selection
                                                                                search={true}
                                                                                multiple={false}
                                                                                options={this.state.orgcontacts}
                                                                                value={this.state.data.rec.organization_contact_id}
                                                                                placeholder='Select Organization'
                                                                                name="organization_contact_id"
                                                                                minCharacters={3}

                                                                            />
                                                                            <label><b>Vendor Signature</b>&nbsp;
                                                                                <span
                                                                                    style={{color: 'red'}}>*</span></label>
                                                                            <Form.Dropdown
                                                                                fluid
                                                                                selection
                                                                                search={true}
                                                                                multiple={false}
                                                                                options={this.state.orgcontacts}
                                                                                value={this.state.data.rec.organization_signature_id}
                                                                                placeholder='Select Signature'
                                                                                name="organization_signature_id"
                                                                                minCharacters={3}

                                                                            />
                                                                        </Segment>

                                                                    </Grid.Column>


                                                                </Grid.Row>
                                                                <Grid.Row style={{paddingTop: "10px"}}>


                                                                    <Grid.Column>
                                                                        <Form.Checkbox
                                                                            defaultChecked={this.state.data.rec.schedule_a}
                                                                            toggle
                                                                            name="schedule_a"
                                                                            label="Schedule A"/></Grid.Column>
                                                                    <Grid.Column>


                                                                    </Grid.Column>
                                                                    <Grid.Column>

                                                                        <Form.Checkbox
                                                                            defaultChecked={this.state.data.rec.has_ceiling}
                                                                            toggle
                                                                            name="has_ceiling" label="Inactive"/>
                                                                    </Grid.Column>
                                                                </Grid.Row>


                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Accordion fluid styled>
                                                                <Accordion.Title active={activeIndex === 1} index={1}
                                                                                 onClick={this.handleAccordionClick}>
                                                                    <Icon name='dropdown'/>
                                                                    Fee for Service
                                                                </Accordion.Title>
                                                                <Accordion.Content active={activeIndex === 1}>
                                                                    <Grid padded={false} columns='equal'>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={8}>
                                                                                <b><u>Description</u></b>
                                                                            </Grid.Column>

                                                                            <Grid.Column width={8}>
                                                                                <b><u>Amount</u></b>
                                                                            </Grid.Column>


                                                                        </Grid.Row>

                                                                        {this.state.data.rec.fee_for_service.map((item, idx) => {
                                                                                if (this.state.data.rec.fee_for_service[idx]["_destroy"] !== "1") {
                                                                                    return (
                                                                                        <Grid.Row key={"row_" + idx}
                                                                                                  style={{
                                                                                                      paddingBottom: "0",
                                                                                                      paddingTop: "3px"
                                                                                                  }}>
                                                                                            <Grid.Column width={8}>
                                                                                                <Form.Input
                                                                                                    name={"fee_for_service." + idx + ".desc"}
                                                                                                    maxLength={255}
                                                                                                    onChange={this.fee_desc_change}
                                                                                                    style={{width: "100%"}}
                                                                                                    value={this.state.data.rec.fee_for_service[idx].desc}

                                                                                                />

                                                                                            </Grid.Column>

                                                                                            <Grid.Column width={6}>
                                                                                                <div style={{
                                                                                                    float: "left",
                                                                                                    paddingTop: "3px"
                                                                                                }}>
                                                                                                    <NumericInput
                                                                                                        value={this.state.data.rec.fee_for_service[idx].amount}
                                                                                                        min={0}
                                                                                                        max={9999999999}
                                                                                                        precision={4}
                                                                                                        strict
                                                                                                        label="Amount"
                                                                                                        onChange={this.NumberChangeTable}
                                                                                                        name={"fee_for_service." + idx + ".amount"}
                                                                                                        format={this.formatCommas}
                                                                                                        parse={this.removeCommas}
                                                                                                    /></div>
                                                                                                <div
                                                                                                    style={{float: "left"}}>
                                                                                                    <Form.Dropdown
                                                                                                        fluid
                                                                                                        selection
                                                                                                        search={true}
                                                                                                        multiple={false}
                                                                                                        options={uom}
                                                                                                        defaultValue={this.state.data.rec.fee_for_service[idx].uom ? this.state.data.rec.fee_for_service[idx].uom : ""}
                                                                                                        onChange={this.uomChange}
                                                                                                        placeholder='UOM'
                                                                                                        style={{
                                                                                                            width: "100px"
                                                                                                        }}
                                                                                                        name={"fee_for_service." + idx + ".uom"}


                                                                                                    /></div>

                                                                                            </Grid.Column>
                                                                                            <Grid.Column width={2}>
                                                                                                &nbsp;&nbsp; <a
                                                                                                style={{marginTop: "5px"}}
                                                                                                onClick={() => {
                                                                                                    this.removeFee(idx)
                                                                                                }}
                                                                                                className="action-link"
                                                                                                primary={true}><Icon
                                                                                                name="minus"
                                                                                            />&nbsp;Remove </a>
                                                                                            </Grid.Column>

                                                                                        </Grid.Row>

                                                                                    )
                                                                                }
                                                                            }
                                                                        )

                                                                        }
                                                                        <Grid.Row>
                                                                            <Grid.Column width={4}>
                                                                                <Button type="button"
                                                                                        size="small"
                                                                                        color="green"
                                                                                        onClick={this.addFee}>
                                                                                    <Icon name='plus'/> Add Fee
                                                                                </Button>

                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Accordion.Content></Accordion>

                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{paddingTop: "5px"}}>
                                                        <Grid.Column width={6}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Resolutions'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>
                                                                <Grid padded={false} columns='equal'>

                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            <Form.Input
                                                                                name="resolution_1"
                                                                                size="small"
                                                                                placeholder="Resolution 1"
                                                                                defaultValue={this.state.data.rec.resolution_1}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            <MaskedInput name="resolution_1_date"
                                                                                         onChange={this.NumberFieldUpdate}
                                                                                         value={this.state.data.rec.resolution_1_date}
                                                                                         mask="##/##/####"

                                                                            />
                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            <Form.Input
                                                                                name="resolution_2"
                                                                                size="small"
                                                                                placeholder="Resolution 2"
                                                                                defaultValue={this.state.data.rec.resolution_2}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            <MaskedInput name="resolution_2_date"
                                                                                         onChange={this.NumberFieldUpdate}
                                                                                         value={this.state.data.rec.resolution_2_date}
                                                                                         mask="##/##/####"

                                                                            />
                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            <Form.Input
                                                                                name="resolution_3"
                                                                                size="small"
                                                                                placeholder="Resolution 3"
                                                                                defaultValue={this.state.data.rec.resolution_3}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            <MaskedInput name="resolution_3_date"
                                                                                         onChange={this.NumberFieldUpdate}
                                                                                         value={this.state.data.rec.resolution_3_date}
                                                                                         mask="##/##/####"

                                                                            />
                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "5px",
                                                                        paddingTop: "0px"
                                                                    }}>
                                                                        <Grid.Column>

                                                                            <Form.Input
                                                                                name="resolution_4"
                                                                                size="small"
                                                                                placeholder="Resolution 4"
                                                                                defaultValue={this.state.data.rec.resolution_4}
                                                                            />
                                                                        </Grid.Column>
                                                                        <Grid.Column width={6}>
                                                                            <MaskedInput name="resolution_4_date"
                                                                                         onChange={this.NumberFieldUpdate}
                                                                                         value={this.state.data.rec.resolution_4_date}
                                                                                         mask="##/##/####"

                                                                            />
                                                                        </Grid.Column>


                                                                    </Grid.Row>


                                                                </Grid>

                                                            </Segment></Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Funding Sources'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>
                                                                <Grid padded={false} columns='equal'>

                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            Federal %

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <NumericInput
                                                                                value={this.state.data.rec.perc_federal}
                                                                                min={0}
                                                                                max={100}
                                                                                precision={2}
                                                                                strict
                                                                                onChange={this.NumberChange}
                                                                                name="perc_federal"
                                                                                format={this.formatCommas}
                                                                                parse={this.removeCommas}


                                                                            /><br/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            State %

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <NumericInput
                                                                                value={this.state.data.rec.perc_state}
                                                                                min={0}
                                                                                max={100}
                                                                                precision={2}
                                                                                strict
                                                                                onChange={this.NumberChange}
                                                                                name="perc_state"
                                                                                format={this.formatCommas}
                                                                                parse={this.removeCommas}


                                                                            /><br/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "0px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            Local %

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <NumericInput
                                                                                value={this.state.data.rec.perc_local}
                                                                                min={0}
                                                                                max={100}
                                                                                precision={2}
                                                                                strict
                                                                                onChange={this.NumberChange}
                                                                                name="perc_local"
                                                                                format={this.formatCommas}
                                                                                parse={this.removeCommas}


                                                                            /><br/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                    <Grid.Row style={{
                                                                        paddingBottom: "5px",
                                                                        paddingTop: "5px"
                                                                    }}>
                                                                        <Grid.Column size={2}
                                                                                     style={{textAlign: "right"}}>
                                                                            Other %

                                                                        </Grid.Column>
                                                                        <Grid.Column width={8}>
                                                                            <NumericInput
                                                                                value={this.state.data.rec.perc_other}
                                                                                min={0}
                                                                                max={100}
                                                                                precision={2}
                                                                                strict
                                                                                onChange={this.NumberChange}
                                                                                name="perc_other"
                                                                                format={this.formatCommas}
                                                                                parse={this.removeCommas}


                                                                            /><br/>

                                                                        </Grid.Column>


                                                                    </Grid.Row>
                                                                </Grid>
                                                            </Segment>
                                                        </Grid.Column>
                                                        <Grid.Column width={4}>
                                                            <Segment raised>
                                                                <Header as='h5' content='Mandate/Revenue'
                                                                        style={{
                                                                            paddingBottom: "0px",
                                                                            textAlign: "center",
                                                                            textDecoration: "underline",
                                                                            fontWeight: "bold"
                                                                        }}/>

                                                                <Form.Radio
                                                                    label='Mandated'
                                                                    name='mandate_classification'
                                                                    value='mandated'
                                                                    onChange={this.ChangeMandate}
                                                                    checked={this.state.mandate_classification === "mandated"}
                                                                />
                                                                <Form.Radio
                                                                    label='Non-Mandated'
                                                                    name='mandate_classification'
                                                                    value='non-mandated'
                                                                    onChange={this.ChangeMandate}
                                                                    checked={this.state.mandate_classification === "non-mandated"}
                                                                />
                                                                <Form.Checkbox
                                                                    defaultChecked={this.state.data.rec.revenue_contract}
                                                                    toggle
                                                                    name="revenue_contract" label="Revenue Contract"/>

                                                            </Segment>
                                                        </Grid.Column>

                                                    </Grid.Row>

                                                </Grid>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>


                                    <Grid.Row style={{paddingTop: "0px"}}>
                                        <Grid.Column width={16}>
                                            <Segment raised>
                                                <Form.TextArea
                                                    name="synopsis_of_program"
                                                    label="WHEREAS, the County desires to obtain services in connection with"
                                                    placeholder="Synposis of Program"
                                                    defaultValue={this.state.data.rec.synopsis_of_program}


                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{paddingTop: "0px"}}>
                                        <Grid.Column width={16}>
                                            <Segment raised>
                                                <Form.TextArea
                                                    name="need_for_services"
                                                    label="Need For Services"
                                                    placeholder="Need for Services"
                                                    defaultValue={this.state.data.rec.need_for_services}


                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{paddingTop: "0px"}}>
                                        <Grid.Column width={16}>
                                            <Segment raised>
                                                <Form.TextArea
                                                    name="notes"
                                                    label="Notes"
                                                    placeholder="Notes"
                                                    defaultValue={this.state.data.rec.notes}


                                                />
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>

                                </Grid>
                            </div>
                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_financial_tab) {
            panes[x] =

                {
                    menuItem:
                        <Menu.Item key="s2a"
                                   className={this.state.recid == null ? "hiddenTab" : ""}>Financial</Menu.Item>,
                    pane:
                        (
                            <Tab.Pane key='tab2a'>
                                <Tab key="fintabtab" renderActiveOnly={false} panes={finpanes}>
                                </Tab>

                            </Tab.Pane>
                        )
                }
            x += 1
        }
        if (current_user.can_access_performance_tab) {

            panes[x] = {
                menuItem: <Menu.Item key="s3"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Performance</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab3'>
                            {current_user.can_create_new_objectives ?
                                <Button size="tiny" color="green" onClick={this.newObjective}>
                                    <Icon name='save'/> New Objective
                                </Button> : ""}
                            <Segment raised>
                                <Header as='h5' icon='file outline' content='Performance Objectives'
                                        style={{paddingBottom: "8px"}}></Header>
                                <ReactTable
                                    key={randomkeypo}
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onDoubleClick: (e) => {
                                                this.setState({
                                                    selected: rowInfo.index,
                                                    selectedName: rowInfo.row.description
                                                })
                                                this.getMeasures(rowInfo.index)
                                            },
                                            style: {
                                                background: rowInfo && (rowInfo.index === this.state.selected) ? 'black' : '',
                                                color: rowInfo && (rowInfo.index === this.state.selected) ? 'white' : 'black'
                                            }

                                        }
                                    }}
                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            onDoubleClick: (e, handleOriginal) => {
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.

                                                if (column.Header === "D") {
                                                    this.DeleteObjective(rowInfo.row.id)
                                                }
                                                if (column.Header === "E") {
                                                    this.EditObjective(rowInfo.row.id)
                                                }

                                            }
                                        }
                                    }}
                                    columns={[

                                        current_user.can_edit_objectives ?
                                            {
                                                Header: "E",
                                                Cell: row => (
                                                    <div>
                                                        <Icon name='edit'/>
                                                    </div>),
                                                width: 30
                                            } : {
                                                Header: "",
                                                style: {display: "none"},
                                                width: 0
                                            },
                                        current_user.can_delete_objectives ?


                                            {
                                                Header: "D",
                                                Cell: row => (
                                                    <div>
                                                        <Icon name='delete'/>
                                                    </div>),
                                                width: 30
                                            } :
                                            {
                                                Header: "",

                                                width: 0,
                                                style: {display: "none"}
                                            },


                                        {
                                            Header: "Id",
                                            accessor: "id",
                                            width: 0,
                                            style: {display: "none"}
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "description",
                                            width: 800
                                        },
                                        {
                                            Header: "Frequency",
                                            accessor: "frequency",
                                            width: 100
                                        }
                                    ]}
                                    showPaginationTop
                                    data={this.state.data.rec.contract_objectives}
                                    pageSize={5}
                                    className="-striped -highlight"
                                />


                            </Segment>
                            <Segment raised>
                                <Header as='h5' icon='file outline'
                                        content={'Performance Measurements - ' + this.state.selectedName}
                                        style={{paddingBottom: "8px"}}></Header>
                                {this.state.selected !== null && current_user.can_create_new_measures ?
                                    <Button size="tiny" color="green" onClick={this.newMeasurement}>
                                        <Icon name='save'/> New Measurement
                                    </Button> : ""}
                                <ReactTable
                                    key={randomkeyom}

                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            onDoubleClick: (e, handleOriginal) => {
                                                // IMPORTANT! React-Table uses onClick internally to trigger
                                                // events like expanding SubComponents and pivots.
                                                // By default a custom 'onClick' handler will override this functionality.
                                                // If you want to fire the original onClick handler, call the
                                                // 'handleOriginal' function.

                                                if (column.Header === "D") {
                                                    this.DeleteMeasurement(rowInfo.row.id)
                                                }
                                                if (column.Header === "E") {
                                                    this.EditMeasurement(rowInfo.row.id)
                                                }

                                            }
                                        }
                                    }}
                                    columns={[
                                        current_user.can_edit_measures ?
                                            {
                                                Header: "E",
                                                Cell: row => (
                                                    <div>
                                                        <Icon name='edit'/>
                                                    </div>),
                                                width: 30
                                            } :
                                            {
                                                Header: "",
                                                style: {display: "none"},
                                                width: 0
                                            }

                                        ,
                                        current_user.can_delete_measures ?

                                            {
                                                Header: "D",

                                                width: 30
                                            } :

                                            {
                                                Header: "",
                                                width: 0,
                                                style: {display: "none"}
                                            }

                                        ,


                                        {
                                            Header: "Id",
                                            accessor: "id",
                                            width: 0,
                                            style: {display: "none"}
                                        },
                                        {
                                            Header: "Description",
                                            accessor: "description",

                                        }
                                    ]}
                                    showPaginationTop
                                    data={this.state.objective_measurements}
                                    pageSize={5}
                                    className="-striped -highlight"
                                />
                            </Segment>
                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_access_tab) {

            panes[x] = {
                menuItem: <Menu.Item key="s5"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Access</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab5'>
                            {current_user.can_add_users_to_contracts ?
                                <div><label><b>Select User to Add Access</b>&nbsp;</label>

                                    <Dropdown
                                        fluid
                                        selection
                                        search={true}
                                        multiple={false}
                                        placeholder='Select User'
                                        name="access_user_id"
                                        ref="access_user_id"
                                        onSearchChange={this.searchUsers}
                                        minCharacters={3}
                                        onChange={this.loadUser}
                                        options={this.state.accessusers}


                                    /></div> : ""}
                            <ReactTable
                                key={randomkey2}

                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onDoubleClick: (e, handleOriginal) => {
                                            // IMPORTANT! React-Table uses onClick internally to trigger
                                            // events like expanding SubComponents and pivots.
                                            // By default a custom 'onClick' handler will override this functionality.
                                            // If you want to fire the original onClick handler, call the
                                            // 'handleOriginal' function.

                                            if (column.Header === "D") {
                                                this.DeleteContractUser(rowInfo.row.id)
                                            }

                                        }
                                    }
                                }}
                                columns={[

                                    current_user.can_delete_users_from_contracts ?
                                        {
                                            Header: "D",
                                            Cell: row => (
                                                <div>
                                                    <Icon name='delete'/>
                                                </div>),
                                            width: 30
                                        } :

                                        {
                                            Header: "",

                                            width: 0
                                        }


                                    ,


                                    {
                                        Header: "Id",
                                        accessor: "id",
                                        width: 0,
                                        style: {display: "none"}
                                    },
                                    {
                                        Header: "Orgs",
                                        accessor: "orgs",
                                        Cell: row => (

                                            <div dangerouslySetInnerHTML={this.createMarkup(row.value)}/>

                                        )
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                        width: 250
                                    },
                                    {
                                        Header: "Name",
                                        accessor: "name",
                                        width: 200
                                    },
                                    {
                                        Header: "Address Info",
                                        accessor: "address",
                                        Cell: row => (

                                            <div dangerouslySetInnerHTML={this.createMarkup(row.value)}/>

                                        )

                                    },
                                    current_user.can_add_users_to_contracts ?
                                        {
                                            Header: "Can Edit",
                                            accessor: "can_edit",
                                            Cell: this.renderEditCheck

                                        } : {
                                            Header: "",
                                            width: 0

                                        }
                                ]}
                                showPaginationTop
                                data={this.state.data.contract_users}
                                className="-striped -highlight"
                            />

                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_routing_tab) {
            panes[x] = {
                menuItem: <Menu.Item key="s4"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Routing</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab4'>

                            {!current_user.can_not_change_routing && current_user.can_create_new_approval_steps ?
                                <Button size="tiny" color="green" onClick={this.newApproval}>
                                    <Icon name='save'/> New Approval Step
                                </Button> : ""}
                            {current_user.can_start_routing ?
                                <Button size="tiny" color="green" onClick={this.startRouting}>
                                    <Icon name='save'/> Start Routing
                                </Button> : ""}
                            {current_user.can_reset_routing ?
                                <Button size="tiny" color="green" onClick={this.resetRouting}>
                                    <Icon name='save'/> Reset Routing
                                </Button> : ""}

                            {!current_user.can_not_change_routing && current_user.can_create_new_approval_steps ?
                                <Button style={{float: "right"}} size="tiny" color="green" onClick={this.copyRouting}>
                                    <Icon name='save'/> Copy Routing
                                </Button> : ""}

                             <div><br/></div>
                            <Dropdown
                                fluid
                                selection
                                search={true}
                                multiple={false}
                                placeholder='Select Default Route'
                                name="default_route_id"
                                onChange={this.loadDefaultRoute}
                                options={this.state.data.rec.default_routes}
                                selectOnBlur={false}
                                selectOnNavigation={true}
                                style={{width: "600px"}}


                            />


                            <br/>
                            <Sortable
                                onChange={(order, sortable, evt) => {
                                    if (!current_user.can_not_change_routing && current_user.can_edit_approval_steps) {
                                        var temparray = this.state.data.rec.routing_users
                                        var newarray = this.mapOrder(temparray, order, "id")
                                        var data = this.state.data
                                        data.rec.routing_users = newarray
                                        this.updateRoutingTree(newarray)
                                        this.setState({data: data})
                                    }
                                }} // Defaults to "div"
                            >   {this.state.data.rec.routing_users ?
                                this.state.data.rec.routing_users.map((d, index) => {
                                    return (
                                        <div className="grabbable" key={d.data.id}
                                             data-id={d.data.id}
                                             style={{width: "500px"}}>
                                            <Segment>
                                                <div>
                                                    {!current_user.can_not_change_routing && current_user.can_delete_approval_steps ?
                                                        <Button size="tiny" color='red' icon="delete"
                                                                id={"dau-" + d.data.id}
                                                                onClick={this.deleteApprovalStep}></Button> : ""}
                                                    {!current_user.can_not_change_routing && current_user.can_edit_approval_steps ?

                                                        <Button
                                                            id={"eau-" + d.data.id} size="tiny" color='green'
                                                            icon="edit"
                                                            onClick={this.editApprovalStep}></Button> : ""}<b>{index + 1}. {d.user.last_name}, {d.user.first_name}-{d.user.email}</b>
                                                </div>
                                                <div style={{fontSize: "95%", marginLeft: "85px"}}>{d.data.desc}</div>
                                                <div style={{
                                                    fontSize: "95%",
                                                    marginLeft: "85px"
                                                }}>{d.data.signatory.toUpperCase()}</div>
                                            </Segment>

                                        </div>

                                    )
                                }) : ""}
                            </Sortable>

                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_contract_documents_tab) {

            panes[x] = {
                menuItem: <Menu.Item key="s6"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Documents</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tabfordocs'>
                            <Tab key="doctabtab" renderActiveOnly={false} panes={docpanes}>
                            </Tab>


                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_messages_tab) {

            panes[x] = {
                menuItem: <Menu.Item key="s7"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Messages</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab7'>
                            <Button size="medium" style={{marginBottom: "8px"}}
                                    color="green"
                                    onClick={this.AddMessage}>
                                <Icon name='envelope outline'/> Add Message
                            </Button>
                            {this.state.showmessageform == true &&
                            <div style={{border: "1px solid black", padding: "5px"}}>

                                <Form style={{marginBottom: "8px"}} ref="messageForm"
                                      onValidSubmit={this.onValidMessageSubmit}>
                                    <Form.Checkbox
                                        name="private_message"
                                        label="This message is private and not accessible by the Vendor."
                                        checked={true}
                                        defaultChecked={true}


                                    />
                                    <Form.Input required name="message_subject" label="Subject"/>
                                    <Form.TextArea required name="message_body" label="Message"/>
                                    <Form.Button content='Post Message' labelPosition='left' icon='edit' color="green"/>
                                </Form></div>}
                            {this.state.data.rec.messages.map((item) => {
                                return (
                                    <Comment style={{border: "1px solid black;"}}>
                                        <Comment.Content>
                                            <Comment.Author><b>From: </b>{item.user} on {item.msg_date}</Comment.Author>
                                            <Comment.Metadata>
                                                <div><b>Subject</b>-{item.subject}</div>
                                            </Comment.Metadata>
                                            <Comment.Text>{item.body}</Comment.Text>

                                        </Comment.Content>
                                    </Comment>
                                )
                            })}

                        </Tab.Pane>
                    )
            }
            x += 1
        }
        if (current_user.can_access_activity_tab) {

            panes[x] = {
                menuItem: <Menu.Item key="s8"
                                     className={this.state.recid == null ? "hiddenTab" : ""}>Activity</Menu.Item>,
                pane:
                    (
                        <Tab.Pane key='tab8'>
                            <ReactTable

                                columns={[

                                    {
                                        Header: "Date",
                                        accessor: "date",
                                        width: 175

                                    },
                                    {
                                        Header: "Description",
                                        accessor: "desc",
                                        width: 600,
                                        Cell: row => (
                                            <div dangerouslySetInnerHTML={{__html: row.value}}/>
                                        ),

                                    },
                                    {
                                        Header: "User",
                                        accessor: "user",
                                        width: 350
                                    }

                                ]}
                                showPaginationTop
                                data={this.state.data.rec.activities}
                                defaultPageSize={50}
                                className="-striped -highlight"
                            />

                        </Tab.Pane>
                    )
            }
            x += 1
        }


        return (

            <div ref="modalref" key={this.state.randomkeyform}>
                <Dimmer active={this.state.showdimmer}>
                    <Loader size='small' active={this.state.showdimmer}>Working.....</Loader></Dimmer>

                {this.state.data.rec.waiting_id && this.state.data.rec.waiting_id > 0 && current_user.can_see_waiting_on ?
                    <Sticky>
                        {this.state.data.rec.waiting_user.id !== this.state.current_user.id ?
                            <div style={{
                                backgroundColor: "black",
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>This contract is currently waiting for approval
                                by {this.state.data.rec.waiting_user.email}[{this.state.data.rec.waiting_user.first_name} {this.state.data.rec.waiting_user.last_name}] </div>
                            : <div style={{
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>This contract is currently waiting for your approval&nbsp;&nbsp;&nbsp;<Button size="mini"
                                                                                                             color="black"
                                                                                                             onClick={this.ApproveContract}>
                                <Icon name='save'/> Review/Approve
                            </Button>
                            </div>}
                    </Sticky> : ""}
                {this.state.data.rec.renewed_from !== "NONE" ?
                    <Sticky>
                        <div style={{
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>This contract was renewed from Contract #
                            {this.state.data.rec.renewed_from} </div>
                    </Sticky>
                    : " "}


                {this.state.data.rec.amended_from !== "NONE" ?
                    <Sticky>

                        <div style={{
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>This contract was amended from Contract #
                            {this.state.data.rec.amended_from} </div>
                    </Sticky>
                    :
                    " "
                }


                {this.state.data.rec.renewed_to !== "NONE" ?
                    <Sticky>

                        <div style={{
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>This contract was renewed toContract #
                            {this.state.data.rec.renewed_to} </div>
                    </Sticky>
                    :
                    " "
                }

                {this.state.data.rec.amended_to !== "NONE" ?
                    <Sticky>

                        <div style={{
                            backgroundColor: "black",
                            color: "white",
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>This contract was amended to Contract #
                            {this.state.data.rec.amended_to} </div>
                    </Sticky>
                    :
                    " "
                }

                {this.state.data.rec.waiting_id && this.state.data.rec.waiting_id > 0 && !current_user.can_see_waiting_on ?
                    <Sticky>
                        {this.state.data.rec.waiting_user.id !== this.state.current_user.id ?
                            ""
                            : <div style={{
                                backgroundColor: "red",
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center"
                            }}>This contract is currently waiting for your approval&nbsp;&nbsp;&nbsp;<Button size="mini"
                                                                                                             color="black"
                                                                                                             onClick={this.ApproveContract}>
                                <Icon name='save'/> Review/Approve
                            </Button>
                            </div>}
                    </Sticky> : ""}
                <Menu>
                    <Menu.Item>
                        {current_user.can_edit_contracts ?
                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button> : ""}
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        {current_user.can_clone_contracts ?
                            <Menu.Item>
                                <Button size="tiny" color="green" onClick={this.handleClone}>
                                    <Icon name='save'/> Clone Contract
                                </Button>
                            </Menu.Item> : ""}
                        {current_user.can_amend_contracts && this.state.data.rec.status == "executed" && this.state.data.rec.amended_to == "NONE" ?
                            <Menu.Item>
                                <Button size="tiny" color="green" onClick={this.handleAmend}>
                                    <Icon name='save'/> Amend Contract
                                </Button>
                            </Menu.Item>
                            : ""}
                        {current_user.can_renew_contracts && this.state.data.rec.status == "executed" && this.state.data.rec.renewed_to == "NONE" ?
                            <Menu.Item>
                                <Button size="tiny" color="green" onClick={this.handleRenew}>
                                    <Icon name='save'/> Renew Contract
                                </Button>
                            </Menu.Item> : ""}
                    </Menu.Menu>


                </Menu>

                <Form ref="editForm" onSubmit={this.onValidSubmit}>


                    <Tab key="tabtab" renderActiveOnly={false} panes={panes}>
                    </Tab>


                </Form>
                <Menu>
                    <Menu.Item>
                        {current_user.can_edit_contracts ?
                            <Button size="tiny" color="green" onClick={this.handleSave}>
                                <Icon name='save'/> Save
                            </Button> : ""}
                    </Menu.Item>

                </Menu>
                <Modal
                    open={this.state.showapproval}
                    size='large' closeIcon
                    onClose={this.handleapprovalclose}

                >

                    <Header icon='browser' content="Contract Approval"/>

                    <Dimmer active={this.state.showdimmer}>
                        <Loader size='small' active={this.state.showdimmer}>Working.....</Loader></Dimmer>

                    <Modal.Content>
                        <Button onClick={this.showContractDocument} size="tiny" color="green">
                            <Icon name='save'/> View Contract Document
                        </Button>
                        <Form ref="signatureForm"
                              onSubmit={this.onSignatureValidSubmit}>

                            <p><b>This contract requires your approval/signature for execution. Please select an action
                                below and push the "Submit Approval/Rejection" button. </b></p>
                            <p><b>Name of Step:</b> {this.state.current_step.desc}&nbsp;</p>

                            {this.state.current_step.signatory === "signature" ?
                                <div>
                                    <Segment raised style={{bordercolor: "green"}}>
                                        <Header as='h5' icon='file outline'
                                                content="Approval and Digital Signature"
                                                style={{
                                                    paddingBottom: "8px",
                                                    backgroundColor: "green",
                                                    color: "white"
                                                }}></Header>
                                        <Form.Radio
                                            label='Digitally Sign This Contract'
                                            name='contract_approval'
                                            value='sign'
                                            onChange={this.ChangeSignature}
                                            checked={this.state.contract_approval === "sign"}
                                        />
                                        {current_user.can_redirect_routing ?
                                            <div>
                                                <p>If you would like to send this contract to someone other than the
                                                    next person, please select the step below<br/></p>
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    search={true}
                                                    multiple={false}
                                                    options={route_steps}
                                                    placeholder='Select Step'
                                                    name="redirect_routing_signature"

                                                /></div>
                                            : ""
                                        }
                                        <p><br/>Click the box above to approve this contract and send it to the next
                                            person whose approval / signature is required. If no more approvals /
                                            signatures are required, this contract will be executed.</p>
                                        <p><b>I agree that I have read and understand the Electronic Submission Notice.
                                            By applying my electronic signature to this document, I am acknowledging
                                            that I accept the terms and conditions set forth in this contract as binding
                                            and official.</b></p>


                                    </Segment>
                                    <Segment raised>
                                        <Header as='h5' icon='file outline'
                                                content="Reject this Contract"
                                                style={{
                                                    paddingBottom: "8px",
                                                    backgroundColor: "red",
                                                    color: "white"
                                                }}></Header>
                                        <Form.Radio
                                            label='Reject This Contract'
                                            name='contract_approval'
                                            value='reject'
                                            onChange={this.ChangeSignature}
                                            checked={this.state.contract_approval === "reject"}
                                        />
                                        {current_user.can_redirect_routing ?
                                            <div>
                                                <p>If you would like to send this contract to someone other than the
                                                    next person, please select the step below<br/></p>
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    search={true}
                                                    multiple={false}
                                                    options={route_steps}
                                                    placeholder='Select Step'
                                                    name="redirect_routing_reject"

                                                /></div>
                                            : ""
                                        }
                                        <p><br/>Click the box above to reject this contract. Please provide a reason /
                                            justification in the box provided below.</p><br/>
                                        <Form.TextArea
                                            autoHeight={true}
                                            name="reject_reason"
                                            label="Reason/Justification"
                                            placeholder="Reason/Justification"


                                        />
                                    </Segment>
                                </div>


                                : <div>
                                    <Segment raised style={{bordercolor: "green"}}>
                                        <Header as='h5' icon='file outline'
                                                content="Approval and Digital Signature"
                                                style={{
                                                    paddingBottom: "8px",
                                                    backgroundColor: "green",
                                                    color: "white"
                                                }}></Header>
                                        <Form.Radio
                                            label='Approve This Contract'
                                            name='contract_approval'
                                            value='approve'
                                            onChange={this.ChangeSignature}
                                            checked={this.state.contract_approval === "approve"}
                                        />
                                        {current_user.can_redirect_routing ?
                                            <div>
                                                <p>If you would like to send this contract to someone other than the
                                                    next person, please select the step below<br/></p>
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    search={true}
                                                    multiple={false}
                                                    options={route_steps}
                                                    placeholder='Select Step'
                                                    name="redirect_routing_approve"

                                                /></div>
                                            : ""
                                        }

                                        <p><br/>Click the box above to approve this contract and send it to the next
                                            person whose approval / signature is required. If no more approvals /
                                            signatures are required, this contract will be executed.</p>
                                        <p><b>I agree that I have read and understand the Electronic Submission Notice.
                                            By applying my electronic signature to this document, I am acknowledging
                                            that I accept the terms and conditions set forth in this contract as binding
                                            and official.</b></p>


                                    </Segment>
                                    <Segment raised>
                                        <Header as='h5' icon='file outline'
                                                content="Reject this Contract"
                                                style={{
                                                    paddingBottom: "8px",
                                                    backgroundColor: "red",
                                                    color: "white"
                                                }}></Header>
                                        <Form.Radio
                                            label='Reject This Contract'
                                            name='contract_approval'
                                            value='reject'
                                            onChange={this.ChangeSignature}
                                            checked={this.state.contract_approval === "reject"}
                                        />
                                        {current_user.can_redirect_routing ?
                                            <div>
                                                <p>If you would like to send this contract to someone other than the
                                                    next person, please select the step below<br/></p>
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    search={true}
                                                    multiple={false}
                                                    options={route_steps}
                                                    placeholder='Select Step'
                                                    name="redirect_routing_reject"

                                                /></div>
                                            : ""
                                        }
                                        <p><br/>Click the box above to reject this contract. Please provide a reason /
                                            justification in the box provided below.</p><br/>
                                        <Form.TextArea
                                            autoHeight={true}
                                            name="reject_reason"
                                            label="Reason/Justification"
                                            placeholder="Reason/Justification"


                                        />
                                    </Segment>
                                </div>


                            }
                            <br/>
                            <Form.Button size="tiny" color="green">
                                <Icon name='save'/> Submit Approval/Rejection
                            </Form.Button><br/>
                        </Form>
                    </Modal.Content>


                </Modal>
                <Modal
                    open={this.state.showinsuranceimport}
                    size='large' closeIcon
                    onClose={this.handleinsuranceclose}


                >

                    <Header icon='browser' content="Import Insurance"/>
                    <Modal.Content>
                        <ReactTable
                            key={randomkey2}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "P") {
                                            this.showInsuranceDocument(rowInfo)
                                        }
                                        if (column.Header === "I") {
                                            this.ImportVendorInsurance(rowInfo)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },
                                {
                                    Header: "I",
                                    Cell: row => (
                                        <div>
                                            <Icon name='download'/>
                                        </div>),
                                    width: 30
                                },


                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 0,
                                    style: {display: "none"}
                                },
                                {
                                    Header: "Type",
                                    accessor: "insurance_type",
                                    width: 250,

                                },
                                {
                                    Header: "Status",
                                    accessor: "status",
                                    width: 200,


                                },
                                {
                                    Header: "Coverage",
                                    accessor: "coverage_per",

                                },
                                {
                                    Header: "Expiration",
                                    accessor: "expiration",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )

                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            data={this.state.data.rec.vendor_insurances}
                            className="-striped -highlight"
                        />
                    </Modal.Content>
                </Modal>
                <Modal
                    open={this.state.showdocsimport}
                    size='large' closeIcon
                    onClose={this.handleimportclose}


                >

                    <Header icon='browser' content="Import Documents"/>
                    <Modal.Content>

                        <ReactTable
                            key={randomkey4}

                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        if (column.Header === "P") {
                                            this.showDocument(rowInfo)
                                        }
                                        if (column.Header === "I") {
                                            this.ImportVendorDoc(rowInfo)
                                        }

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "P",
                                    Cell: row => (
                                        <div>
                                            <Icon name='file pdf outline'/>
                                        </div>),
                                    width: 30
                                },
                                {
                                    Header: "I",
                                    Cell: row => (
                                        <div>
                                            <Icon name='download'/>
                                        </div>),
                                    width: 30
                                },


                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 60
                                },
                                {
                                    Header: "File Name",
                                    accessor: "filename"
                                },
                                {
                                    Header: "Date Created",
                                    accessor: "created_at",
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }
                            ]}
                            showPaginationTop
                            data={this.state.data.rec.vendor_documents}
                            className="-striped -highlight"
                        />

                    </Modal.Content>
                </Modal>

                <Modal
                    open={this.state.showroutingmodal}
                    size='large' closeIcon
                    onClose={this.handleroutingclose}


                >

                    <Header icon='browser' content="Approval Step"/>
                    <Modal.Content>
                        <Form ref="editForm"
                              onSubmit={current_user.can_edit_contracts ? this.onRoutingValidSubmit : ""}>


                            <Menu>
                                <Menu.Item>

                                    <Button size="tiny" color="green" onClick={this.handleRoutingSave}>
                                        <Icon name='save'/> Save
                                    </Button>
                                </Menu.Item>

                            </Menu><br/>
                            <Grid padded={false} columns='equal'>

                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>
                                    <Grid.Column width={6}>
                                        <Form.Input
                                            name="id"
                                            defaultValue={this.state.route.id ? this.state.route.id.toString() : null}
                                            style={{display: "none"}}
                                        />
                                        <Form.Input
                                            required
                                            name="desc"
                                            size="small"
                                            label="Approval Step Name"
                                            placeholder="Approval Step Name"
                                            errorLabel={<Label color="red" pointing/>}
                                            defaultValue={this.state.route.desc}

                                            validationErrors={{
                                                isDefaultRequiredValue: 'Approval Step Name is Required',
                                            }}/><label><b>Contract User</b>&nbsp;<span
                                        style={{color: 'red'}}>*</span></label>

                                        <Form.Dropdown
                                            fluid
                                            selection
                                            search={true}
                                            multiple={false}
                                            options={possible_users}
                                            value={this.state.route.user_id ? this.state.route.user_id : null}
                                            placeholder='Select User'
                                            name="user_id"

                                        />
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Segment raised>
                                            <Header as='h5' icon='file outline' content='Approval/Signature'
                                                    style={{paddingBottom: "8px"}}></Header>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Approval Only'
                                                    name='signatory'
                                                    value='approval'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "approval"}
                                                />
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    multiple={false}
                                                    placeholder='Select Stamp'
                                                    name="approval_role"
                                                    options={soptions}
                                                    value={this.state.route.approval_role ? this.state.route.approval_role : null}


                                                />
                                            </Segment>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Approval/Signature'
                                                    name='signatory'
                                                    value='signature'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "signature"}
                                                />
                                                <Form.Dropdown
                                                    fluid
                                                    selection
                                                    multiple={false}
                                                    placeholder='Select Stamp'
                                                    name="signature_role"
                                                    options={soptions}
                                                    value={this.state.route.signature_role ? this.state.route.signature_role : null}


                                                />
                                            </Segment>
                                            <Segment raised>

                                                <Form.Radio
                                                    label='Notify Only'
                                                    name='signatory'
                                                    value='notify'
                                                    onChange={this.ChangeApproval}
                                                    checked={this.state.route.signatory === "notify"}
                                                />
                                            </Segment>
                                        </Segment>

                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Segment raised>

                                            <Form.Checkbox
                                                name="locked"
                                                label="Lock after Approval"
                                                value={this.state.route.locked}
                                                defaultChecked={this.state.route.locked}

                                            />
                                            <Form.Checkbox
                                                name="phase_end"
                                                label="Phase End"
                                                value={this.state.route.phase_end}
                                                defaultChecked={this.state.route.phase_end}

                                            />
                                        </Segment>
                                    </Grid.Column>

                                </Grid.Row>

                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>
                                    <Grid.Column width={16}>
                                        <Segment raised>
                                            <Form.TextArea
                                                autoHeight={true}
                                                name="message"
                                                label="Message/Instructions"
                                                placeholder="Message/Instructions"
                                                defaultValue={this.state.route.message}


                                            />
                                        </Segment>
                                    </Grid.Column></Grid.Row>
                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>

                                </Grid.Row>
                            </Grid>

                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleroutingclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.showobjectivemodal}
                    size='large' closeIcon
                    onClose={this.handleobjectiveclose}


                >

                    <Header icon='browser' content="Performance Objective"/>
                    <Modal.Content>
                        <Form ref="editObjectiveForm"
                              onSubmit={current_user.can_edit_contracts ? this.onObjectiveValidSubmit : ""}>

                            <Menu>
                                <Menu.Item>

                                    <Button size="tiny" color="green" onClick={this.handleObjectiveSave}>
                                        <Icon name='save'/> Save
                                    </Button>
                                </Menu.Item>

                            </Menu><br/>
                            <Grid padded={false} columns='equal'>

                                <Grid.Row style={{margin: 0, paddingTop: 0, paddingBottom: 5}}>
                                    <Grid.Column width={4}>
                                        <Segment raised>
                                            <Header as='h5' icon='file outline' content='Frequency'
                                                    style={{paddingBottom: "8px"}}></Header>

                                            <Form.Radio
                                                label='Monthly'
                                                name='frequency'
                                                value='M'
                                                onChange={this.ChangeOFrequency}
                                                checked={this.state.objective.frequency === "M"}
                                            />
                                            <Form.Radio
                                                label='Annual'
                                                name='frequency'
                                                value='A'
                                                onChange={this.ChangeOFrequency}
                                                checked={this.state.objective.frequency === "A"}
                                            />
                                        </Segment></Grid.Column>
                                    <Grid.Column width={12}>
                                        <Segment raised>
                                            <Form.TextArea
                                                autoHeight={true}
                                                name="description"
                                                label="Objective Description"
                                                placeholder="Objective Descriptionn"
                                                defaultValue={this.state.objective.description}


                                            />
                                        </Segment></Grid.Column></Grid.Row></Grid>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handleobjectiveclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>


                <Modal
                    open={this.state.showcopymodal}
                    size='large' closeIcon
                    onClose={this.handlecopyclose}


                >

                    <Header icon='browser' content="Routing Copy"/>
                    <Modal.Content>
                        <Menu style={{paddingBottom: "0px", margin: "0px"}}>

                            <Menu.Item>
                                <Button size="tiny" icon='refresh' label="Refresh Page" onClick={this.refreshctable}/>
                            </Menu.Item>

                            <Menu.Menu position='right'>
                                <Menu.Item>
                                    <Form ref="searchcform" onValidSubmit={this.onValidCSubmit}>
                                        <Form.Input name="search" action={{icon: 'search'}} placeholder='Search...'
                                                    onChange={this.handleCChange}/>
                                    </Form>
                                </Menu.Item>
                            </Menu.Menu>
                        </Menu>
                        <ReactTable
                            key={randomkeyccr}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    onDoubleClick: (e, handleOriginal) => {
                                        // IMPORTANT! React-Table uses onClick internally to trigger
                                        // events like expanding SubComponents and pivots.
                                        // By default a custom 'onClick' handler will override this functionality.
                                        // If you want to fire the original onClick handler, call the
                                        // 'handleOriginal' function.
                                        this.copyroutes(rowInfo)

                                    }
                                }
                            }}
                            columns={[

                                {
                                    Header: "Id",
                                    accessor: "id",
                                    width: 0,
                                    style: {display: "none"}
                                },
                                {
                                    Header: "",
                                    Cell: row => (
                                        <div>
                                            <Icon name='copy'/>
                                        </div>),
                                    width: 30
                                },
                                {
                                    Header: "Number",
                                    accessor: "contract_no",
                                    width: 100

                                },
                                {
                                    Header: "Department",
                                    accessor: "contract_depts_d"
                                },
                                {
                                    Header: "Vendor",
                                    accessor: "organization.name"
                                },
                                {
                                    Header: "Name",
                                    accessor: "name"
                                },
                                {
                                    Header: "Start Date",
                                    accessor: "start_date",
                                    width: 100,
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )

                                }
                                ,
                                {
                                    Header: "End Date",
                                    accessor: "end_date",
                                    width: 100,
                                    Cell: row => (
                                        <div>
                                            {row.value === null ? " " :
                                                <Moment format="MM/DD/YYYY">
                                                    {row.value}
                                                </Moment>
                                            }
                                        </div>
                                    )
                                }


                            ]}
                            showPaginationTop
                            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                            data={cdata}
                            pages={cpages} // Display the total number of pages
                            loading={cloading} // Display the loading overlay when we need it
                            onFetchData={this.fetchCData} // Request new data when things change
                            defaultPageSize={20}
                            className="-striped -highlight"
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button size="tiny" color='green' onClick={this.handlecopyclose} inverted>
                            <Icon name='checkmark'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>

            </div>

        );
    }
}


